<template>
  <div v-if="this.isAdmin != true && this.isSupport != true"> {{ this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div> 
  <!--begin::Content wrapper-->
  <div v-else class="d-flex flex-column flex-column-fluid">
    <!--begin::Toolbar-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6" style="padding-top: 0px !important;padding-bottom: 10px !important;">
      <!--begin::Toolbar container-->
      <div
        id="kt_app_toolbar_container"
        class="app-container d-flex flex-stack"
      >
        <!--begin::Page title-->
        <div
          class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
        >
          <!--begin::Title-->
          <h1
            class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          >
          {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.Title }}
          </h1>
          <!--end::Title--> 
        </div>
        <!--end::Page title-->
      </div>
      <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card header-->
          <div class="card-header border-0 pt-6" style="align-items: center;padding-top: 0px !important;">
            <!--begin::Card title-->
            <div class="card-title">
              <!--begin::Search-->
              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span @click="onEnterSearchFirst" class="path1" style="cursor:pointer;z-index: 2;"></span>
                  <span class="path2"></span>
                </i>
                <input
                  v-on:keyup.enter="onEnterSearchFirst"
                  v-model="inputValue"
                  type="text"
                  class="form-control form-control-solid w-250px ps-12"
                  :placeholder="this.$NOLIMITResource[this.dataUser.language].UserManagement.TextSearch"
                />
              </div>
              <!--end::Search-->
            </div>
            <!--begin::Card title-->
            <!--begin::Card toolbar-->
           
            <div class="card-toolbar" style="margin-left: auto;">
              <i @click="toggleModalDisplayRow" class="ki-outline ki-gear fs-2x me-3"></i>
              <router-link to="/permissionUser" class="btn btn-sm fw-bold btn-primary" style="margin-right: 10px">
                Phân quyền        </router-link>
                <router-link to="/allCommission" class="btn btn-sm fw-bold btn-primary" >
                  Tất cả hoa hồng        </router-link>
              <!--begin::Toolbar-->
              <div
                class="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                  data-kt-menu="true"
                  id="kt-toolbar-filter"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-4 text-gray-900 fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Separator-->
                  <!--begin::Content-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Month:</label
                      >
                      <!--end::Label-->
                      <!--begin::Input-->
                      <select
                        class="form-select form-select-solid fw-bold"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-customer-table-filter="month"
                        data-dropdown-parent="#kt-toolbar-filter"
                      >
                        <option></option>
                        <option value="aug">August</option>
                        <option value="sep">September</option>
                        <option value="oct">October</option>
                        <option value="nov">November</option>
                        <option value="dec">December</option>
                      </select>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Payment Type:</label
                      >
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div
                        class="d-flex flex-column flex-wrap fw-semibold"
                        data-kt-customer-table-filter="payment_type"
                      >
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="all"
                            checked="checked"
                          />
                          <span class="form-check-label text-gray-600"
                            >All</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="visa"
                          />
                          <span class="form-check-label text-gray-600"
                            >Visa</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="mastercard"
                          />
                          <span class="form-check-label text-gray-600"
                            >Mastercard</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="american_express"
                          />
                          <span class="form-check-label text-gray-600"
                            >American Express</span
                          >
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="reset"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="filter"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Menu 1-->
                <!--end::Filter-->
              </div>
              <!--end::Toolbar-->
              <!--begin::Group actions-->
              <div
                class="d-flex justify-content-end align-items-center d-none"
                data-kt-customer-table-toolbar="selected"
              >
                <div class="fw-bold me-5">
                  <span
                    class="me-2"
                    data-kt-customer-table-select="selected_count"
                  ></span
                  >Selected
                </div>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-kt-customer-table-select="delete_selected"
                >
                  Delete Selected
                </button>
              </div>
              <!--end::Group actions-->
            </div>
            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body pt-0" style="margin-top: 20px;">
            <!--begin::Table-->
            <table 
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <!-- <th class="min-w-125px">UserId</th> -->
                  <!-- <th v-if="this.displayRow.username" class="min-w-125px">Tên đăng nhập</th>
                  <th v-if="this.displayRow.roleUser" class="min-w-125px">Phân quyền</th>
                  <th v-if="this.displayRow.fullnameUser" class="min-w-125px">Họ tên</th>
                  <th v-if="this.displayRow.email" class="min-w-125px">Email</th>
                  <th v-if="this.displayRow.statusBanUser" class="min-w-125px">Trạng thái</th>
                  <th v-if="this.displayRow.balanceUser" class="min-w-125px">Tiền còn lại</th>
                  <th v-if="this.displayRow.totalMoneyUser" class="min-w-125px">Tổng tiền đã nạp</th>
                  <th v-if="this.displayRow.topupFeeUser" class="min-w-125px">Phí Topup (%)</th> -->
                  <template v-for="(column, index) in columns" :key="index">
                    <th
                      v-if="shouldDisplay(index)"
                      class="min-w-125px"
                      :class="{
                        sorting_desc: this.sortingNumber == index,
                        sorting_asc: this.sortingAsc == index,
                      }"
                      :style="{ 'min-width': column.width + 'px !important' }"
                    >
                      <template v-if="shouldDisplay(index)">
                        <div @mousedown="this.startResize($event, index)">
                          <span
                            @click="
                              onSorting(index, column.property, 'changeSort')
                            "
                            >{{ column.label }}</span
                          >
                        </div>
                      </template>
                    </th>
                  </template>


                  <th  v-if="this.isAdmin" class="text-end min-w-70px"> {{ this.$NOLIMITResource[this.dataUser.language].Feature }}</th>
                  <th v-else style="min-width: 0px !important; width: 0px !important;"></th>
                </tr>
              </thead>
              <div class="loading-1" v-if="onLoading">
                <span  class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div style="margin-top: 20px;" v-if="!this.onLoading && this.hasData == false">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.Nodata }}</div>    
              <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                <tr v-for="user in users" :key="user.userId">
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ user.userId }}</a
                    >
                  </td>
                  <td v-if="this.displayRow.username">
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ user.username }}</a
                    >
                  </td>
                  <td v-if="this.displayRow.roleUser">
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >
                      <div
                        v-if="user.isAdmin"
                        :class="`badge badge-light-success`"
                      >
                        Admin
                      </div>
                      <div v-else :class="`badge badge-light-danger`">
                        User
                      </div>
                      </a
                    >
                    <a
                      class="text-gray-800 text-hover-primary mb-1" style="margin-left:5px"
                      >
                      <div
                        v-if="user.isAccountant"
                        :class="`badge badge-light-primary`"
                      >
                        Accountant
                      </div>
                      </a
                    >
                    <a
                      class="text-gray-800 text-hover-primary mb-1" style="margin-left:5px"
                      >
                      <div
                        v-if="user.isAffiliate"
                        :class="`badge badge-light-primary`"
                      >
                        Affiliate
                      </div>
                      </a
                    >
                    <a
                      class="text-gray-800 text-hover-primary mb-1" style="margin-left:5px"
                      >
                      <div
                        v-if="user.isSupport"
                        :class="`badge badge-light-primary`"
                      >
                        Support
                      </div>
                      </a
                    >
                  </td>
                  <td v-if="this.displayRow.fullnameUser">
                    <a class="text-gray-600 text-hover-primary mb-1">{{
                      user.name
                    }}</a>
                  </td>
                  <td v-if="this.displayRow.email">{{ user.email }}</td>
                  <td v-if="this.displayRow.statusBanUser">
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                        v-if="!user.isBan"
                        :class="`badge badge-light-success`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.StatusWork }}
                      </div>
                      <div v-else :class="`badge badge-light-danger`">
                        {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.StatusInactive }}
                      </div>
                    </a>
                  </td>
                  <td v-if="this.displayRow.balanceUser">{{ formatCurrency(user.balance) }}</td>
                  <td v-if="this.displayRow.totalMoneyUser">{{ formatCurrency(user.totalMoney) }}</td>
                  <td v-if="this.displayRow.moneyOutUser">{{ formatCurrency(user.moneyOut) }}</td>
                  <td v-if="this.displayRow.moneyInUser">{{ formatCurrency(user.moneyIn) }}</td>
                  <td v-if="this.displayRow.topupFeeUser">{{ user.topUpFee }}</td>
                  <td  v-if="this.isAdmin" class="text-end" :class="{
                      active__update__combobox:
                        onShowMenu && currenOnshowId === user.userId,
                    }">
                    <a
                      @click="toggleMenuItem(user.userId)"
                    
                      class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      >{{ this.$NOLIMITResource[this.dataUser.language].SeeDetail }} <i class="ki-duotone ki-down fs-5 ms-1"></i
                    ></a>
                    <!--begin::Menu-->
                    <div
                      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                      :class="{
                        show: onShowMenu && currenOnshowId === user.userId,
                      }"
                      data-kt-menu="true"
                    >
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a
                          @click="openModalUpdateUser(user.userId)"
                        
                          class="menu-link px-3"
                          > {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.UpdateInformation }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                       <!--begin::Menu item-->
                       <div class="menu-item px-3">
                        <a
                          @click="openModalUpdateUser(user.userId, 'TopUp')"
                        
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.ChangeTopupFee }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a
                          @click="openModalChangeMoney(user.userId, 'Plus')"
                        
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AddMoney }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a
                          @click="openModalChangeMoney(user.userId, 'Minus')"
                        
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.MinusMoney }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                        <a
                          @click="openModalRefundMoney(user.userId)"
                        
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.RefundMoney }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a
                          v-if="!user.isBan"
                          @click="banUser(user.userId)"
                        
                          class="menu-link px-3"
                          data-kt-customer-table-filter="delete_row"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.LockAccount }}</a
                        >
                        <a
                          v-else
                          @click="unBanUser(user.userId)"
                        
                          class="menu-link px-3"
                          data-kt-customer-table-filter="delete_row"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.UnlockAccount }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      
                            
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a v-if="user.isAccountant"
                          @click="openModalProvideUserManager(user.userId)"
                        
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.ProvideUserforAccountant }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                       
                         <!--begin::Menu item-->
                         <div class="menu-item px-3">
                        <a
                          @click="openModalCommission(user.userId)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.Commission }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu-->
                  </td>
                  <td v-else style="min-width: 0px !important; width: 0px !important;"></td>
                </tr>
                <tr id="total">
                   <td class="fix-data-footer"  colspan="3" style="font-weight: bold"> <img src="./../../../../public/frontend/media/logos/logo3.png" style="width:30px;height:auto"/>  {{this.userAll.length}}  {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.User }}</td> 
                   <td></td>
                   <td></td>
                   <td></td>
                   <td></td>
                   <td v-if="this.displayRow.totalMoneyUser">{{formatCurrency(totalRecharge)}}</td>
                   <td></td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div
                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
              >
                <div class="dataTables_length" id="kt_customers_table_length">
                  <label>
                    <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                <div
                  class="dataTables_paginate paging_simple_numbers"
                  id="kt_customers_table_paginate"
                >
                  <ul v-if="this.totalPage >= 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                      
                        aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in 4"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                      
                        aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                      
                        aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                  <ul v-if="this.totalPage < 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                      
                        aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in totalPage"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                      
                        aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                      
                        aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->

        <!--begin::Modal - User - Commission-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalCommission }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px" style="justify-content: center;">
            <!--begin::Modal content-->
            <div class="modal-content" style="width:160%">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.Commission }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalCommission"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                  <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="min-w-125px">UserId</th>
                  <th class="min-w-125px">AdsId</th>
                  <th class="min-w-125px">LatestSpending</th>
                  <th class="min-w-125px">CommissionRate</th>
                  <th class="min-w-125px">LastUpdated</th>
                  <th class="min-w-125px">CreatedDate</th>
                  <th class="text-end min-w-70px"></th>
                </tr>
              </thead>
              <div class="loading" v-if="onLoadingCommission">
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div id="noData" v-if="!this.onLoadingCommission && this.hasDataCommission == false">
                {{ this.$NOLIMITResource[this.dataUser.language].EmptyData }}
              </div>
              <tbody
                v-if="!this.onLoadingCommission && this.hasDataCommission == true"
                class="fw-semibold text-gray-600"
              >
                <tr
                  v-for="commission in listCommissionByUser"
                  :key="commission.id"
                >
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ commission.userId }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ commission.adsId }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ formatCurrency(commission.latestSpending) }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ formatCurrency(commission.commissionRate) }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ formatDateTime(commission.lastUpdated) }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ formatDateTime(commission.createdDate) }}</a
                    >
                  </td>
                  <td class="text-end" style="display: flex; height: 75px;">
                  
                    <a @click="confirmDeleteCommision(commission.id)"
                      class="btn btn-light-danger btn-sm"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      > {{ this.$NOLIMITResource[this.dataUser.language].Setting.Delete }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
                    
                     
                 
                 
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalCommission"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="button"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                    @click="openModalAddCommission()"
                  >
                  
                    <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AddCommissionAdAccount }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="button"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                    @click="openModalAddCommissionMulti()"
                  >
                  
                    <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AddCommissionAdAccountMulti }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - User - Commission-->
 <!--begin::Modal - Add Many Commission-->
 <div
          class="modal fade show"
          :class="{ block: isShowModalAddCommissionMulti }"
          id="kt_customers_export_modal"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold">Thêm hàng loạt (IdAds|Phần trăm hoa hồng(%))</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div
                  id="kt_customers_export_close"
                  class="btn btn-icon btn-sm btn-active-icon-primary"
                >
                  <i class="ki-duotone ki-cross fs-1" @click="closeModalAddCommissionMulti">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
                <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form" action="" @submit.prevent="convertStringToArray()">
                  
                  <!--begin::Input group-->
                  <div class="input-group input-group-solid " style="margin-bottom: 50px;">
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>Thêm hàng loạt</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        title="Thêm hàng loạt"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                      <textarea v-model="this.commissions" class="form-control" style="width: 100%;" aria-label="With textarea"></textarea>
                  </div>
                  <!--end::Input group-->


                  <!--begin::Actions-->
                  <div class="text-center">
                    <button
                      type="reset"
                      id="kt_customers_export_cancel"
                      class="btn btn-light me-3"
                      @click="closeModalAddCommissionMulti"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_customers_export_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label">Thêm</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - Add Many Commission-->


         <!--begin::Modal - Result Many Commission-->
 <div
          class="modal fade show"
          :class="{ block: isShowModalResultCommissionMulti }"
          id="kt_customers_export_modal"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold">Kết quả thêm hàng loạt liên kết hoa hồng</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div
                  id="kt_customers_export_close"
                  class="btn btn-icon btn-sm btn-active-icon-primary"
                >
                  <i class="ki-duotone ki-cross fs-1" @click="closeModalResultCommissionMulti">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
                <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form" action="" @submit.prevent="convertStringToArray()">
                  
                  <div v-for="(Commission, index) in listSuccessCommission" :key="index" class="d-flex align-items-center mb-5">             
                    <span class="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">{{Commission}} </span>                                                 
                          <i class="ki-duotone ki-check-circle fs-1 text-success"><span class="path1"></span><span class="path2"></span></i>                                                                                                                                                      
                  </div>
                  <div v-for="(Commission, index) in listErrorCommission" :key="index"  class="d-flex align-items-center mb-5">             
                    <span class="fw-semibold fs-6 text-gray-600 flex-grow-1">
                      {{Commission}}                                              </span> 
                          <i class="ki-duotone ki-cross-circle fs-1"><span class="path1"></span><span class="path2"></span></i>                                                                                                                                                      
                  </div>


                  <!--begin::Actions-->
                  <div class="text-center">
                    <button
                      type="reset"
                      id="kt_customers_export_cancel"
                      class="btn btn-light me-3"
                      @click="closeModalResultCommissionMulti"
                    >
                    Đóng
                    </button>
                 
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - Result Many Commission-->
        <!--begin::Modal - Add Commission-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalAddCommission }"
          id="kt_modal_new_card"
          role="dialog"
          tabindex="-1"
          aria-modal="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2>{{this.$NOLIMITResource[this.dataUser.language].UserManagement.AddCommissionAdAccount}}</h2>

                <div
                  @click="closeModalAddCommission"
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form
                  id="kt_modal_new_card_form"
                  class="form"
                  action=""
                  @submit.prevent="addCommission"
                >
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>AdsId</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="'AdsId'"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.commissionAdd.adsId"
                    />
                  </div>
                  <!--end::Input group-->
                
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{this.$NOLIMITResource[this.dataUser.language].UserManagement.PercentCommission}}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="'%'"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.commissionAdd.commissionRate"
                    />
                  </div>
                  <!--end::Input group-->

                  <!--begin::Actions-->
                  <div class="text-center pt-15">
                    <button
                      @click="closeModalAddCommission"
                      type="reset"
                      id="kt_modal_new_card_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_card_submit"
                      class="btn btn-primary"
                    >
                      <span
                        class="indicator-label"
                        >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AddCommissionAdAccount }}</span
                      >
                    
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - Add Commission -->
        <!--begin::Modal - User - Update-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalUpdateUser }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="updateUserInfor"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 v-if="this.formMode != 'TopUp'" class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.UpdateUserInformation }}</h2>
                  <h2 v-else class="fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.ChangeTopupFee }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateUser"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{ this.$NOLIMITResource[this.dataUser.language].UserName }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleUsername"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        readonly
                        v-model="userCurrent.username"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{ this.$NOLIMITResource[this.dataUser.language].FullName }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleFullname"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.name"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode == 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{ this.$NOLIMITResource[this.dataUser.language].TopupFeePercent }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].AdsAccount.TopupFeeTitle"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.topUpFee"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Email</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleEmail"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.email"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.PhoneNumber }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitlePhone"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.phone"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Facebook</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleFacebook"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.facebook"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Telegram</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleTelegram"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.telegram"
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Zalo</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleZalo"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.zalo"
                      />
                    </div>
                    <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{this.$NOLIMITResource[this.dataUser.language].UserManagement.RemainCommission}}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                       
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.remainingCommission"
                      />
                    </div>
                    <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="d-flex flex-column mb-7 fv-row" v-if="this.formMode != 'TopUp'">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{this.$NOLIMITResource[this.dataUser.language].UserManagement.WithdrawnCommission}}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="userCurrent.withdrawnCommission"
                      />
                    </div>
                    <!--end::Input group-->
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateUser"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].Save }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>
        <!--end::Modal - User - Update-->
        <!--begin::Modal - change money-->
        <div
          class="modal fade show"
          :class="{ block: isShowModal }"
          id="kt_modal_new_card"
          role="dialog"
          tabindex="-1"
          aria-modal="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2 v-if="this.formMode === 'Plus'">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AddMoney }}</h2>
                <h2 v-else>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.MinusMoney }}</h2>
                <div
                  @click="closeModalChangeMoney"
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form
                  id="kt_modal_new_card_form"
                  class="form"
                  action=""
                  @submit.prevent="changeMoneyUser"
                >
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.BalanceNow }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleBalanceNow"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      readonly
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="formatCurrentBalanceUserDisplay"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AmountDeposited }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleAmountDeposited"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      readonly
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="formatCurrentTotalMoneyUserDisplay"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label v-if="this.formMode == 'Plus'"
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AmountWantToAdd }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleAmountWantToAdd"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                     <!--begin::Label-->
                     <label v-else
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AmountWantToMinus }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleAmountWantToMinus"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.changeMoney"

                    />
                  </div>
                  <!--end::Input group-->
                  <p v-if="this.formMode == 'Plus'" style="color:#1e94ce;text-align: start;margin-left: 10px;">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.ThePlusAmountIs }}: {{formatChangeMoneyDisplay}}</p>

                  <p v-else style="color:#1e94ce;text-align: start;margin-left: 10px;">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.TheMinusAmountIs }}: {{formatChangeMoneyDisplay}}</p>

                  <!--begin::Actions-->
                  <div class="text-center pt-15">
                    <button
                      @click="closeModalChangeMoney"
                      type="reset"
                      id="kt_modal_new_card_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_card_submit"
                      class="btn btn-primary"
                    >
                      <span
                        v-if="this.formMode == 'Plus'"
                        class="indicator-label"
                        >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AddMoney }}</span
                      >
                      <span v-else class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.MinusMoney }}</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - change money-->



        <!--begin::Modal - refund money-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalRefundMoney }"
          id="kt_modal_new_card"
          role="dialog"
          tabindex="-1"
          aria-modal="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.RefundMoney }}</h2>
                <div
                  @click="closeModalRefundMoney"
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form
                  id="kt_modal_new_card_form"
                  class="form"
                  action=""
                  @submit.prevent="refundMoneyUser"
                >
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.BalanceNow }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleBalanceNow"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      readonly
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="formatCurrentBalanceUserDisplay"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.FacebookAdsIdRefund }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.FacebookAdsIdRefund"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.facebookAdsIdRefund"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AmountWantToRefund }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].UserManagement.TitleAmountWantToRefund"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                   
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.refundMoney"

                    />
                  </div>
                  <!--end::Input group-->
                  <p style="color:#1e94ce;text-align: start;margin-left: 10px;">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.TheRefundAmountIs }}: {{formatRefundMoneyDisplay}}</p>

                 

                  <!--begin::Actions-->
                  <div class="text-center pt-15">
                    <button
                      @click="closeModalRefundMoney"
                      type="reset"
                      id="kt_modal_new_card_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_card_submit"
                      class="btn btn-primary"
                    >
                      <span
                        class="indicator-label"
                        >{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.RefundMoney }}</span
                      >
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - refund money-->

        <!--begin::Modal -  Cấp user quản lý-->
         <div
            class="modal fade show"
            :class="{ block: isShowModalProvideUserManager }"
            id="kt_modal_add_customer"
            tabindex="-1"
            aria-hidden="true"
          >
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered mw-650px">
              <!--begin::Modal content-->
              <div class="modal-content">
                <!--begin::Form-->
                <form
                  class="form"
                  id="kt_modal_add_customer_form"
                  action=""
                  @submit.prevent="addMediateAccountantUser"
                >
                  <!--begin::Modal header-->
                  <div class="modal-header" id="kt_modal_add_customer_header">
                    <!--begin::Modal title-->
                    <h2 class="fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.GrantUserIdToManagementAccounting }}</h2>
                    <!--end::Modal title-->
                    <!--begin::Close-->
                    <div
                      @click="closeModalProvideUserManager"
                      id="kt_modal_add_customer_close"
                      class="btn btn-icon btn-sm btn-active-icon-primary"
                    >
                      <i class="ki-duotone ki-cross fs-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </i>
                    </div>
                    <!--end::Close-->
                  </div>
                  <!--end::Modal header-->
                  <!--begin::Modal body-->
                  <div class="modal-body py-10 px-lg-17">
                    <!--begin::Scroll-->
                    <div
                      class="scroll-y me-n7 pe-7"
                      id="kt_modal_add_customer_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                      data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <!--begin::Input group-->
                      <div class="d-flex flex-column mb-7 fv-row">
                        <!--begin::Label-->
                        <label
                          class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                        >
                          <span>UserId</span>
                          <span
                            class="ms-1"
                            data-bs-toggle="tooltip"
                            title="UserId mà kế toán được quản lý"
                          >
                            <i
                              class="ki-duotone ki-information-5 text-gray-500 fs-6"
                            >
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                            </i>
                          </span>
                        </label>
                        <!--end::Label-->
                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder=""
                          name="card_name"
                          v-model="inputValue"
                        />
                      </div>
                      <!--end::Input group-->
                    
                    </div>
                    <!--end::Scroll-->
                  </div>
                  <!--end::Modal body-->
                  <!--begin::Modal footer-->
                  <div class="modal-footer flex-center">
                    <!--begin::Button-->
                    <button
                      @click="closeModalProvideUserManager"
                      type="reset"
                      id="kt_modal_add_customer_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <!--end::Button-->
                    <!--begin::Button-->
                    <button
                      type="submit"
                      id="kt_modal_add_customer_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].UserManagement.GrantToAccounting }}</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                    <!--end::Button-->
                  </div>
                  <!--end::Modal footer-->
                </form>
                <!--end::Form-->
              </div>
            </div>
            
          </div>
          <!--end::Modal - Cấp user quản lý -->

        <!--begin::Modal - Adjust Balance-->
        <div
          class="modal fade"
          id="kt_customers_export_modal"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold">Export Customers</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div
                  id="kt_customers_export_close"
                  class="btn btn-icon btn-sm btn-active-icon-primary"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
                <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form" action="#">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="fs-5 fw-semibold form-label mb-5"
                      >Select Export Format:</label
                    >
                    <!--end::Label-->
                    <!--begin::Input-->
                    <select
                      data-control="select2"
                      data-placeholder="Select a format"
                      data-hide-search="true"
                      name="format"
                      class="form-select form-select-solid"
                    >
                      <option value="excell">Excel</option>
                      <option value="pdf">PDF</option>
                      <option value="cvs">CVS</option>
                      <option value="zip">ZIP</option>
                    </select>
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="fs-5 fw-semibold form-label mb-5"
                      >Select Date Range:</label
                    >
                    <!--end::Label-->
                    <!--begin::Input-->
                    <input
                      class="form-control form-control-solid"
                      placeholder="Pick a date"
                      name="date"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Row-->
                  <div class="row fv-row mb-15">
                    <!--begin::Label-->
                    <label class="fs-5 fw-semibold form-label mb-5"
                      >Payment Type:</label
                    >
                    <!--end::Label-->
                    <!--begin::Radio group-->
                    <div class="d-flex flex-column">
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          checked="checked"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >All</span
                        >
                      </label>
                      <!--end::Radio button-->
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="2"
                          checked="checked"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >Visa</span
                        >
                      </label>
                      <!--end::Radio button-->
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="3"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >Mastercard</span
                        >
                      </label>
                      <!--end::Radio button-->
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="4"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >American Express</span
                        >
                      </label>
                      <!--end::Radio button-->
                    </div>
                    <!--end::Input group-->
                  </div>
                  <!--end::Row-->
                  <!--begin::Actions-->
                  <div class="text-center">
                    <button
                      type="reset"
                      id="kt_customers_export_cancel"
                      class="btn btn-light me-3"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      id="kt_customers_export_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label">Submit</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - New Card-->
        <!--end::Modals-->
        <!--begin::Modal - Pickrow-->
        <div class="modal fade show " :class="{ block: isShowModalPickRow }">
          </div>
          <div id="kt_drawer_chat" class="bg-body drawer drawer-end " :class="{'drawer-on':isShowModalPickRow}" data-kt-drawer="true" data-kt-drawer-name="chat" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'300px', 'md': '500px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_drawer_chat_toggle" data-kt-drawer-close="#kt_drawer_chat_close" style="width: 500px !important;z-index: 50000;">
			<!--begin::Messenger-->
			<div class="card w-100 border-0 rounded-0" id="kt_drawer_chat_messenger">
				<!--begin::Card header-->
				<div class="card-header pe-5" id="kt_drawer_chat_messenger_header" >
					<!--begin::Title-->
					<div class="card-title">
						<!--begin::User-->
						<div class="d-flex justify-content-center flex-column me-3">
							<a href="#" class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 lh-1"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ChooseDisplayColumns }}</a>
							
						</div>
						<!--end::User-->
					</div>
					<!--end::Title-->
					<!--begin::Card toolbar-->
					<div class="card-toolbar">
						<!--begin::Menu-->
						<div class="me-0">
							
							<!--begin::Menu 3-->
							<div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">
								<!--begin::Heading-->
								<div class="menu-item px-3">
									<div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Contacts</div>
								</div>
								<!--end::Heading-->
								<!--begin::Menu item-->
								<div class="menu-item px-3">
									<a href="#" class="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add Contact</a>
								</div>
								<!--end::Menu item-->
								<!--begin::Menu item-->
								<div class="menu-item px-3">
									<a href="#" class="menu-link flex-stack px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">Invite Contacts 
									<span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a contact email to send an invitation" data-bs-original-title="Specify a contact email to send an invitation" data-kt-initialized="1">
										<i class="ki-duotone ki-information fs-7">
											<span class="path1"></span>
											<span class="path2"></span>
											<span class="path3"></span>
										</i>
									</span></a>
								</div>
								<!--end::Menu item-->
								<!--begin::Menu item-->
								<div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
									<a href="#" class="menu-link px-3">
										<span class="menu-title">Groups</span>
										<span class="menu-arrow"></span>
									</a>
									<!--begin::Menu sub-->
									<div class="menu-sub menu-sub-dropdown w-175px py-4">
										<!--begin::Menu item-->
										<div class="menu-item px-3">
											<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Create Group</a>
										</div>
										<!--end::Menu item-->
										<!--begin::Menu item-->
										<div class="menu-item px-3">
											<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Invite Members</a>
										</div>
										<!--end::Menu item-->
										<!--begin::Menu item-->
										<div class="menu-item px-3">
											<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Settings</a>
										</div>
										<!--end::Menu item-->
									</div>
									<!--end::Menu sub-->
								</div>
								<!--end::Menu item-->
								<!--begin::Menu item-->
								<div class="menu-item px-3 my-1">
									<a href="#" class="menu-link px-3" data-bs-toggle="tooltip" data-bs-original-title="Coming soon" data-kt-initialized="1">Settings</a>
								</div>
								<!--end::Menu item-->
							</div>
							<!--end::Menu 3-->
						</div>
						<!--end::Menu-->
						<!--begin::Close-->
						<div class="btn btn-sm btn-icon btn-active-color-primary" id="kt_drawer_chat_close">
							<i @click="toggleModalDisplayRow" class="ki-duotone ki-cross-square fs-2">
								<span class="path1"></span>
								<span class="path2"></span>
							</i>
						</div>
						<!--end::Close-->
					</div>
					<!--end::Card toolbar-->
				</div>
				<!--end::Card header-->
				<!--begin::Card body-->
        <form action="" @submit.prevent="onSubmitModalPickRow">
				<div class="card-body" id="kt_drawer_chat_messenger_body">
					<!--begin::Messages-->
          
					<div class="scroll-y me-n5 pe-5" data-kt-element="messages" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer" data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body" data-kt-scroll-offset="0px" style="height: calc(100vh - 200px);overflow: scroll;">
					
						<div class="form-check">
              <input v-model="this.checkRow.username" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].UserName }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.roleUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.AssignPermissions }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.fullnameUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].FullName }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.email" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].Email }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.statusBanUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].Status }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.balanceUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].Balance }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.totalMoneyUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].TotalMoney }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.moneyOutUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].MoneyOut }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.moneyInUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].MoneyIn }}
              </label>
          </div>
          <div class="form-check">
              <input v-model="this.checkRow.topupFeeUser" class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label class="form-check-label" for="flexCheckChecked">
                {{ this.$NOLIMITResource[this.dataUser.language].TopupFeePercent }}
              </label>
          </div>
          
					</div>
					<!--end::Messages-->
				</div>
				<!--end::Card body-->
				<!--begin::Card footer-->
				<div class="card-footer pt-4" id="kt_drawer_chat_messenger_footer">
				
					<!--begin:Toolbar-->
					<div class="d-flex flex-stack" style="justify-content: flex-end;">
						
						<!--begin::Send-->
						<button class="btn btn-primary" type="submit" data-kt-element="send">{{ this.$NOLIMITResource[this.dataUser.language].Save }}</button>
						<!--end::Send-->
					</div>
					<!--end::Toolbar-->
				</div>
      </form>	
				<!--end::Card footer-->
			</div>
			<!--end::Messenger-->
    </div> 
		<!--end::Modal - Pickrow-->
      </div>
      <!--end::Content container-->
    </div>
    <!--end::Content-->
  </div>
</template>
<style scoped>
.table {
   overflow: scroll;
    height: calc(100vh - 275px);
    display: block;
}

.active__update__combobox {
  z-index: 555 !important;
}
.menu {
  left: -20px;
  top: 53px;
  position: relative;
}
td {
  text-align: start;
}
.menu-sub-dropdown {
  position: absolute;
}
.block {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-1{
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
}
.min-w-125px{
    min-width:250px !important
  }
  .form-check{
    display: flex;
    /* min-height: 1.5rem; */
    height: 30px;
    padding-left: 2.25rem;
    margin-bottom: 0.125rem;
    align-items: center;
  }
  .form-check-input{
    margin-right: 10px;
  }
  .fix-data-footer{
    position: sticky;
    left:0px;
    background: #fff;
    z-index: 10;
  }
  .table tr#total{
  position: sticky;
    bottom: 0px;
    left: 0;
    background: #fff;
    z-index: 4;
}
.table tr#total{
  position: sticky;
}
</style>
<script>
import { mapActions,mapGetters} from "vuex";
import router from '@/router/router.js';
export default {
  name: "UserList",
  data() {
    return {
      facebookAdsIdRefund:"",
      isShowModalRefundMoney:false,
      listErrorCommission:[],
      listSuccessCommission:[],
      isShowModalResultCommissionMulti:false,
      commissions:"",
      isShowModalAddCommissionMulti:false,
      onLoadingCommission:true,
      hasDataCommission:true,
      isShowModalAddCommission:false,
      commissionAdd:{},
      listCommissionByUser:[],
      isShowModalCommission:false,
      dataUser:{language:"VN"},
      currentIndex: null,
      currentProperty: null,
      sortingAsc: null,
      sortingNumber: null,
      columns: [
        { label: this.$NOLIMITResource["VN"].UserManagement.UserId, width: 250, property: "UserId"  },
        { label: this.$NOLIMITResource["VN"].UserName, width: 250, property: "Username"  },
        { label: this.$NOLIMITResource["VN"].UserManagement.AssignPermissions, width: 250, property: "IsAdmin"  },
        { label: this.$NOLIMITResource["VN"].FullName, width: 250, property: "Name"  },
        { label: this.$NOLIMITResource["VN"].Email, width: 250, property: "Email"  },
        { label: this.$NOLIMITResource["VN"].Status, width: 250, property: "IsBan"  },
        { label: this.$NOLIMITResource["VN"].Balance, width: 250, property: "Balance"  },
        { label: this.$NOLIMITResource["VN"].TotalMoney, width: 250, property: "TotalMoney"  },
        { label: this.$NOLIMITResource["VN"].MoneyOut, width: 250, property: "MoneyOut"  },
        { label: this.$NOLIMITResource["VN"].MoneyIn, width: 250, property: "MoneyIn"  },
        { label: this.$NOLIMITResource["VN"].TopupFeePercent, width: 250, property: "TopUpFee"  },
       
      ],
      numberPerPage:[10,25,50,100],
      onLoading:true,
      users: [],
      totalRecharge:0,
      onShowMenu: false,
      currenOnshowId: 0,
      userCurrent: {},
      isShowModal: false,
      currentBalanceUser: 0,
      currentTotalMoneyUser: 0,
      isShowModalUpdateUser: false,
      changeMoney: 0,
      refundMoney: 0,
      formMode: "",
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      firstPage: 0,
      inputValue: "",
      previousBalance:0,
      newBalance:0,
      isAdmin:true,
      isSupport:true,
      hasData:true,
      checkRow:{},
      displayRow:{},
      isShowModalPickRow:false,
      idUser:0,
      userAll:[],
      isShowModalProvideUserManager:false,
      currentMediateAccountantUser:{},
    };
  },
  watch: {
    dataUser:{
      handler: function() {
        this.columns = [
        { label: this.$NOLIMITResource[this.dataUser.language].UserManagement.UserId, width: 250, property: "UserId"  },
        { label: this.$NOLIMITResource[this.dataUser.language].UserName, width: 250, property: "Username"  },
        { label: this.$NOLIMITResource[this.dataUser.language].UserManagement.AssignPermissions, width: 250, property: "IsAdmin"  },
        { label: this.$NOLIMITResource[this.dataUser.language].FullName, width: 250, property: "Name"  },
        { label: this.$NOLIMITResource[this.dataUser.language].Email, width: 250, property: "Email"  },
        { label: this.$NOLIMITResource[this.dataUser.language].Status, width: 250, property: "IsBan"  },
        { label: this.$NOLIMITResource[this.dataUser.language].Balance, width: 250, property: "Balance"  },
        { label: this.$NOLIMITResource[this.dataUser.language].TotalMoney, width: 250, property: "TotalMoney"  },
        { label: this.$NOLIMITResource[this.dataUser.language].MoneyOut, width: 250, property: "MoneyOut"  },
        { label: this.$NOLIMITResource[this.dataUser.language].MoneyIn, width: 250, property: "MoneyIn"  },
        { label: this.$NOLIMITResource[this.dataUser.language].TopupFeePercent, width: 250, property: "TopUpFee"  },
       
      ];
      }
    },

    pageSize() {
      this.reDisplay();
    },
    userAll(){
      this.totalRecharge = 0;
      for(let i = 0; i<this.userAll.length;i++){
        if(this.userAll[i].isAdmin == false){
          this.totalRecharge += this.userAll[i].totalMoney
        }
      }
      

    }

  },
  computed:{
    ...mapGetters("listModule", [
      "formatCurrency","formatDateTime"
    ]),

    formatCurrentBalanceUserDisplay() {
      return this.formatCurrency(this.currentBalanceUser) + " VND";
    },
    formatCurrentTotalMoneyUserDisplay() {
      return this.formatCurrency(this.currentTotalMoneyUser) + " VND";
    },
    formatChangeMoneyDisplay() {
      return this.formatCurrencyValue(this.changeMoney) + " VND";
    },
    formatRefundMoneyDisplay() {
      return this.formatCurrencyValue(this.refundMoney) + " VND";
    },
  },
  created() {
    this.$apiService
      .getUserProfile()
      .then((response) => {
        if(response.data.data.language == "VN" || response.data.data.language == "EN"){
          this.dataUser = response.data.data;
        }else{
            this.dataUser = response.data.data;
            this.dataUser.language = "VN";
        }
        this.idUser = response.data.data.userId;
        this.isAdmin = response.data.data.isAdmin;
        this.isSupport = response.data.data.isSupport;
        if(response.data.data.isAdmin != true && response.data.data.isSupport != true){
                this.isAdmin=false;
                this.isSupport=false;
                router.push({ name: 'ErrorNotFound' });
            }
      this.$apiService
        .getAllUserPanigation(this.pageNumber, this.pageSize)
        .then((res) => {
          setTimeout(()=>{this.onLoading=false},1000)
          this.users = res.data.data;
          if(res.data.data.length == 0){
            this.hasData = false;
          }
          this.totalPage = res.data.pageData.pageCount;
          this.$apiService.getDisplayPropertiesByUserId(response.data.data.userId)
          .then((res)=>{
            this.displayRow = res.data
          })
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
        
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });

      this.$apiService.getAllUserNoPaging(
          ).then((res)=>{
            this.userAll = res.data.data;
          })   
  },

  methods: {
    ...mapActions("listModule", ["toggleMenuFilter","swalSuccess","swalError","swalWarning"]),
    openModalChangeMoney(id, formModeText) {
      this.isShowModal = true;
      this.formMode = formModeText;
      this.$apiService
        .getUserById(id)
        .then((res) => {
          this.userCurrent = res.data.data;
          this.currentBalanceUser = this.userCurrent.balance;
          this.currentTotalMoneyUser = this.userCurrent.totalMoney;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    closeModalChangeMoney() {
      this.isShowModal = false;
      this.onShowMenu = false;
    },
    openModalRefundMoney(id){
      this.isShowModalRefundMoney = true;
      this.$apiService
        .getUserById(id)
        .then((res) => {
          this.userCurrent = res.data.data;
          this.currentBalanceUser = this.userCurrent.balance;
          this.currentTotalMoneyUser = this.userCurrent.totalMoney;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    closeModalRefundMoney(){
      this.isShowModalRefundMoney = false;
      this.onShowMenu = false;
    },
    refundMoneyUser(){
      this.isShowModalRefundMoney = false;
      this.$apiService.refundMoneyUser(this.userCurrent.userId,this.refundMoney,this.facebookAdsIdRefund).then(()=>{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessRefundMoney);
         }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
    },
    changeMoneyUser() {
      this.isShowModal = false;
      if (this.formMode == "Plus") {
        // this.previousBalance = this.userCurrent.balance;
        
        // this.userCurrent.balance =
        //   parseInt(this.currentBalanceUser) + parseInt(this.changeMoney);
        //   this.newBalance = parseInt(this.currentBalanceUser) + parseInt(this.changeMoney)
        // this.userCurrent.moneyIn +=
        //    parseInt(this.changeMoney);
        // this.$apiService
        //   .adminUpdateUserProfile(this.userCurrent.userId, this.userCurrent)
        //   .then(() => {
        //     let balanceHistory = {
        //       message: "Bạn được thêm tiền vào tài khoản bởi Admin",
        //       previousBalance: this.previousBalance, 
        //       amountDifference: parseInt(this.changeMoney),
        //       newBalance: this.newBalance,
        //       userId: this.userCurrent.userId,
        //       username: this.userCurrent.username,
        //       tag: "Admin cộng tiền",
        //       messageEn: "You have money added to your account by Admin",
        //     }
        //     this.$apiService.changeBalanceHistoryById(balanceHistory)
        //     this.onShowMenu = false;
        //     this.changeMoney = 0;
        //     this.onEnterSearch();
        //     this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessPlusMoney);
        //   })
        //   .catch((error) => {
        //     console.error("Lỗi khi lấy dữ liệu:", error);
        //   });
         this.$apiService.plusMoneyUser(this.userCurrent.userId,this.changeMoney).then(()=>{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessPlusMoney);
         }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });

      } else {
        // if (this.userCurrent.balance < this.changeMoney) {
        //   this.swalError({message:this.$NOLIMITResource[this.dataUser.language].UserManagement.ErrorNotEnoughMinus,language: this.dataUser.language})
        // } else {
        //   this.previousBalance = this.userCurrent.balance;
        //   this.userCurrent.balance = this.currentBalanceUser - this.changeMoney;
        //   this.newBalance = this.currentBalanceUser - this.changeMoney;
        //   this.userCurrent.moneyOut += parseInt(this.changeMoney);
        //   this.$apiService
        //     .adminUpdateUserProfile(this.userCurrent.userId, this.userCurrent)
        //     .then(() => {
        //       let balanceHistory = {
        //       message: "Bạn bị trừ tiền vào tài khoản bởi Admin",
        //       previousBalance: this.previousBalance, 
        //       amountDifference: this.changeMoney,
        //       newBalance: this.newBalance,
        //       userId: this.userCurrent.userId,
        //       username: this.userCurrent.username,
        //       tag: "Admin trừ tiền",
        //       messageEn: "You have money deducted to your account by Admin",
        //     }
        //     this.$apiService.changeBalanceHistoryById(balanceHistory)
        //       this.onEnterSearch();
        //       this.onShowMenu = false;
        //       this.changeMoney = 0;
        //       this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessMinusMoney);
        //     })
        //     .catch((error) => {
        //       console.error("Lỗi khi lấy dữ liệu:", error);
        //     });
        // }
        this.$apiService.minusMoneyUser(this.userCurrent.userId,this.changeMoney).then(()=>{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessMinusMoney);
         }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
      }
    },
    toggleModalDisplayRow(){
      this.checkRow = this.displayRow;
      this.isShowModalPickRow = !this.isShowModalPickRow;
      
    },

    openModalProvideUserManager(id){
      this.isShowModalProvideUserManager = true;
      this.onShowMenu = false;
      this.userCurrent.userId = id;
    },

    closeModalProvideUserManager(){
      this.isShowModalProvideUserManager = false;
      this.onShowMenu = false;
    },
    openModalUpdateUser(id,formModeText) {
      this.formMode = formModeText;
      this.isShowModalUpdateUser = true;
      this.$apiService
        .getUserById(id)
        .then((res) => {
          this.userCurrent = res.data.data;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    closeModalUpdateUser() {
      this.isShowModalUpdateUser = false;
      this.onShowMenu = false;
    },
    updateUserInfor() {
      this.isShowModalUpdateUser = false;
      this.$apiService
        .adminUpdateUserProfile(this.userCurrent.userId, this.userCurrent)
        .then(() => {
          this.onShowMenu = false;
          this.onEnterSearch();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessUpdateUserInfo)
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    toggleMenuItem(id) {
      this.onShowMenu = !this.onShowMenu;
      this.currenOnshowId = id;
    },
    onSubmitModalPickRow(){
        this.displayRow = this.checkRow
        this.toggleModalDisplayRow()
        this.$apiService.updateDisplayPropertiesUserByUserId(this.idUser,this.displayRow)
        .then(()=>{

            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessPickColumns)
        })
        
    },
    provideAccountant(id){
      this.$apiService.changeRoleAccountant(id,true).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessGrantToAccounting)
        this.onEnterSearch();
      }).catch((error) => {
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        });
    },
    returnAccountant(id){
      this.$apiService.changeRoleAccountant(id,false).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessRecallAccounting)
        this.onEnterSearch();
      }).catch((error) => {
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        });
    },

    provideAffiliate(id){
      this.$apiService.changeRoleAffiliate(id,true).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessGrantToAffiliate)
        this.onEnterSearch();
      }).catch((error) => {
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        });
    },
    returnAffiliate(id){
      this.$apiService.changeRoleAffiliate(id,false).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessRecallAffiliate)
        this.onEnterSearch();
      }).catch((error) => {
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        });
    },
    provideSupport(id){
      this.$apiService.changeRoleSupport(id,true).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessGrantToSupport)
        this.onEnterSearch();
      }).catch((error) => {
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        });
    },
    returnSupport(id){
      this.$apiService.changeRoleSupport(id,false).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessRecallSupport)
        this.onEnterSearch();
      }).catch((error) => {
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        });
    },
    banUser(id) {
      this.$apiService
        .getUserById(id)
        .then((res) => {
          this.userCurrent = res.data.data;
          this.userCurrent.isBan = true;
          this.$apiService
            .adminUpdateUserProfile(id, this.userCurrent)
            .then(() => {
              this.onEnterSearch();
              this.onShowMenu = false;
              this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessLockAccount);
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    unBanUser(id) {
      this.$apiService
        .getUserById(id)
        .then((res) => {
          this.userCurrent = res.data.data;
          this.userCurrent.isBan = false;
          this.$apiService
            .adminUpdateUserProfile(id, this.userCurrent)
            .then(() => {
              this.onEnterSearch();
              this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessUnlockAccount);
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    selectPage(index) {
      this.pageNumber = index;
      if (this.totalPage >= 4) {
        if (index == this.totalPage) {
          this.firstPage = index - 4;
        } else if (index >= 3) {
          this.firstPage = index - 3;
        } else {
          this.firstPage = 0;
        }
      }
      this.onEnterSearch();
    },
    reDisplay() {
      this.hasData = true;
      this.$apiService
        .getAllUserPanigation(this.pageNumber, this.pageSize)
        .then((res) => {
          this.onLoading=true
          setTimeout(()=>{this.onLoading=false},1000)
          this.users = res.data.data;
          if(res.data.data.length == 0){
                this.hasData = false;
              }
          this.totalPage = res.data.pageData.pageCount;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
        this.$apiService.getAllUserNoPaging(
          ).then((res)=>{
            this.userAll = res.data.data;
          }) 
    },
    /**
     * Chọn next trang tiếp theo trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    nextPage() {
      if (this.pageNumber != this.totalPage) {
        this.pageNumber += 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      this.onEnterSearch();
    },
    /**
     * Chọn prev trang trước đó trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    prevPage() {
      if (this.pageNumber != 1) {
        this.pageNumber -= 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      this.onEnterSearch();
    },
    onEnterSearch() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getAllUserPanigationSearch(
            this.pageNumber,
            this.pageSize,
            this.inputValue
          )
          .then((res) => {
            this.onLoading=true
            setTimeout(()=>{this.onLoading=false},1000)
            this.users = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
           
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
          this.$apiService.getAllUserNoPagingSearch(
            this.inputValue
          ).then((res)=>{
            this.userAll = res.data.data;
          })  
      }
    },
    onEnterSearchFirst() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.pageNumber = 1;
        this.firstPage = 0;
        this.$apiService
          .getAllUserPanigationSearch(
            this.pageNumber,
            this.pageSize,
            this.inputValue
          )
          .then((res) => {
            this.onLoading=true
            setTimeout(()=>{this.onLoading=false},1000)
            this.users = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
       
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
          this.$apiService.getAllUserNoPagingSearch(
            this.inputValue
          ).then((res)=>{
            this.userAll = res.data.data;
          })  
      }
    },

    choosePageSize(event) {
      this.pageSize = event.target.value;
      this.reDisplay();
    },

    startResize(event, index) {
        if (event.offsetX > event.target.offsetWidth - 10) {
                this.isResizing = true;
                this.initialX = event.clientX;
                this.initialWidth = this.columns[index].width;
                this.resizingCol = index;

                document.addEventListener('mousemove', this.handleMouseMove);
                document.addEventListener('mouseup', this.handleMouseUp);
        } 
      },
      handleMouseMove(event) {
          if (this.isResizing && this.resizingCol !== null) {
              const delta = event.clientX - this.initialX;
              const newWidth = Math.max(50, this.initialWidth + delta);
              this.columns[this.resizingCol] = { ...this.columns[this.resizingCol], width: newWidth };
          }
      },
      handleMouseUp() {
          this.isResizing = false;
          this.initialX = 0;
          this.initialWidth = 0;
          this.resizingCol = null;

          document.removeEventListener('mousemove', this.handleMouseMove);
          document.removeEventListener('mouseup', this.handleMouseUp);
      },
    shouldDisplay(index) {
      switch (index) {
        case 1:
          return this.displayRow.username;
        case 2:
          return this.displayRow.roleUser;
        case 3:
          return this.displayRow.fullnameUser;
        case 4:
          return this.displayRow.email;
        case 5:
          return this.displayRow.statusBanUser;
        case 6:
          return this.displayRow.balanceUser;
        case 7:
          return this.displayRow.totalMoneyUser;
        case 8:
          return this.displayRow.moneyOutUser;
          case 9:
          return this.displayRow.moneyInUser;
          case 10:
          return this.displayRow.topupFeeUser;
        // Thêm các trường hợp khác tương ứng
        default:
          return true; // Hiển thị mặc định nếu không có điều kiện nào khớp
      }
    },
    onSorting(index, property, mode) {
      this.currentIndex = index;
      this.currentProperty = property;
      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getUsersSortAsc(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.users = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getUsersSortDesc(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.users = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getUsersSortDesc(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.users = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getUsersSortAsc(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.users = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getUsersSortDesc(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.users = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
    },
    formatCurrencyValue(value) {
      // Chuyển đổi số thành chuỗi
      let stringValue = value.toString();

      // Chia chuỗi thành mảng các nhóm mỗi 3 chữ số
      let parts = stringValue.split('.');
      let mainPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Nếu có phần thập phân, thêm vào chuỗi
      let result = parts.length > 1 ? mainPart + ',' + parts[1] : mainPart;

      return result;
    },
    addMediateAccountantUser(){
      this.currentMediateAccountantUser.userId = parseInt(this.inputValue)
      this.currentMediateAccountantUser.accountantId = this.userCurrent.userId;
      this.$apiService.createMediateAccountantUser(this.currentMediateAccountantUser).then(()=>{

        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].UserManagement.SuccessUserManagementPermissionGrantedSuccessfully)
        this.closeModalProvideUserManager();

      }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
    },
    openModalCommission(id){
      this.hasDataCommission = true;
      this.onLoadingCommission = true;
      this.commissionAdd.userId = null;
      this.$apiService.getAllCommissionByUserId(id).then((res)=>{
        setTimeout(()=>{this.onLoadingCommission=false},1000)
        this.listCommissionByUser = res.data.data;
          if(res.data.data.length == 0){
            this.hasDataCommission = false;
          }
     
        this.commissionAdd.userId = id;
      })
      this.isShowModalCommission = true;
    },
    closeModalCommission(){
      this.isShowModalCommission = false;
    },
    openModalAddCommission(){
      this.commissionAdd.adsId = null;
      this.commissionAdd.commissionRate = null;
      this.isShowModalAddCommission = true;
    },
    closeModalAddCommission(){
      this.isShowModalAddCommission = false;
    },
    addCommission(){
      this.onLoadingCommission=true;
      this.hasDataCommission = true;
        this.$apiService.addCommission(this.commissionAdd).then(()=>{
           this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessAdd)
           this.$apiService.getAllCommissionByUserId(this.commissionAdd.userId).then((res)=>{
              setTimeout(()=>{this.onLoadingCommission=false},1000)
              this.listCommissionByUser = [...res.data.data];
                if(res.data.data.length == 0){
                  this.hasDataCommission = false;
                }
                  
            })
        }).catch((error) => {
          this.swalError({message:error.response.data.message, language: this.dataUser.language})
        });
    },
    async confirmDeleteCommision(id){
      const result = await this.swalWarning({ message: this.$NOLIMITResource[this.dataUser.language].UserManagement.ConfirmDeleteCommission, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.deleteCommission(id)
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },
    deleteCommission(id){
      this.$apiService.deleteCommission(id).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessDeleteBank)
        this.$apiService.getAllCommissionByUserId(this.commissionAdd.userId).then((res)=>{
        setTimeout(()=>{this.onLoadingCommission=false},1000)
        this.listCommissionByUser = res.data.data;
          if(res.data.data.length == 0){
            this.hasDataCommission = false;
          }
     
      })
      })
    },
    openModalAddCommissionMulti(){
      this.isShowModalAddCommissionMulti = true;
    },
    closeModalAddCommissionMulti(){
      this.isShowModalAddCommissionMulti = false;
    },
    convertStringToArray() {
      const formData = new FormData();
        formData.append('commissionData', this.commissions);
        this.$apiService.importManyCommission(formData,this.commissionAdd.userId)
        .then((res)=>{
          this.reDisplay()
          this.swalSuccess("Thêm hàng loạt thành công!")
          this.listErrorCommission = res.data.errors;
          this.listSuccessCommission = res.data.success;
          this.openModalResultCommissionMulti();
        }).catch((error)=>{
          this.swalError( { message:error.response.data.message, language: this.dataUser.language})
        })
    },
    openModalResultCommissionMulti(){
      this.isShowModalResultCommissionMulti = true;
    },
    closeModalResultCommissionMulti(){
      this.isShowModalResultCommissionMulti = false;
    },
  },
};
</script>
