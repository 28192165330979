<template>
    <div v-if="this.isAdmin != true && this.isSupport != true"> {{ this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div> 
      <!--begin::Content wrapper-->
      <div v-else class="d-flex flex-column flex-column-fluid">
        <!--begin::Toolbar-->
        <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6" style="padding-top: 0px !important;padding-bottom: 10px !important;">
          <!--begin::Toolbar container-->
          <div
            id="kt_app_toolbar_container"
            class="app-container d-flex flex-stack"
          >
            <!--begin::Page title-->
            <div
              class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
            >
              <!--begin::Title-->
              <h1
                class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
              >
              {{ this.$NOLIMITResource[this.dataUser.language].TopupThai.Title }}
              </h1>
              <!--end::Title-->
            </div>
            <!--end::Page title-->
          </div>
          <!--end::Toolbar container-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid">
          <!--begin::Content container-->
          <div id="kt_app_content_container" class="app-container">
            <!--begin::Card-->
            <div class="card">
             <!--begin::Card header-->
            <div class="card-header border-0 pt-6" style="align-items: center;padding-top: 0px !important;">
              <!--begin::Card title-->
              <div class="card-title">
                <!--begin::Search-->
                <div class="d-flex align-items-center position-relative my-1">
                  <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                    <span @click="onEnterSearchFirst" class="path1" style="cursor:pointer;z-index: 2;"></span>
                    <span class="path2"></span>
                  </i>
                  <input
                    v-on:keyup.enter="onEnterSearchFirst"
                    v-model="inputValue"
                    type="text"
                    class="form-control form-control-solid w-350px ps-12"
                    :placeholder="this.$NOLIMITResource[this.dataUser.language].TopupThai.SearchText"
                  />
                </div>
                <!--end::Search-->
              </div>
              
              <!--begin::Card toolbar-->
              <div class="card-toolbar" style="margin-left: auto;">
                <!--begin::Toolbar-->
                <div class="content__toolbar--table">
  
                   <a @click="exportCSV()" id="selectedall__toolbar"> {{ this.$NOLIMITResource[this.dataUser.language].DownloadCSV }}</a>
                </div>
                <div
                  class="d-flex justify-content-end"
                  data-kt-customer-table-toolbar="base"
                >
                  <!--begin::Menu 1-->
                  <div
                    class="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                    data-kt-menu="true"
                    id="kt-toolbar-filter"
                  >
                    <!--begin::Header-->
                    <div class="px-7 py-5">
                      <div class="fs-4 text-gray-900 fw-bold">Filter Options</div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Separator-->
                    <div class="separator border-gray-200"></div>
                    <!--end::Separator-->
                    <!--begin::Content-->
                    <div class="px-7 py-5">
                      <!--begin::Input group-->
                      <div class="mb-10">
                        <!--begin::Label-->
                        <label class="form-label fs-5 fw-semibold mb-3"
                          >Month:</label
                        >
                        <!--end::Label-->
                        <!--begin::Input-->
                        <select
                          class="form-select form-select-solid fw-bold"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-customer-table-filter="month"
                          data-dropdown-parent="#kt-toolbar-filter"
                        >
                          <option></option>
                          <option value="aug">August</option>
                          <option value="sep">September</option>
                          <option value="oct">October</option>
                          <option value="nov">November</option>
                          <option value="dec">December</option>
                        </select>
                        <!--end::Input-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div class="mb-10">
                        <!--begin::Label-->
                        <label class="form-label fs-5 fw-semibold mb-3"
                          >Payment Type:</label
                        >
                        <!--end::Label-->
                        <!--begin::Options-->
                        <div
                          class="d-flex flex-column flex-wrap fw-semibold"
                          data-kt-customer-table-filter="payment_type"
                        >
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="all"
                              checked="checked"
                            />
                            <span class="form-check-label text-gray-600"
                              >All</span
                            >
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="visa"
                            />
                            <span class="form-check-label text-gray-600"
                              >Visa</span
                            >
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-3"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="mastercard"
                            />
                            <span class="form-check-label text-gray-600"
                              >Mastercard</span
                            >
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="american_express"
                            />
                            <span class="form-check-label text-gray-600"
                              >American Express</span
                            >
                          </label>
                          <!--end::Option-->
                        </div>
                        <!--end::Options-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Actions-->
                      <div class="d-flex justify-content-end">
                        <button
                          type="reset"
                          class="btn btn-light btn-active-light-primary me-2"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="reset"
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="filter"
                        >
                          Apply
                        </button>
                      </div>
                      <!--end::Actions-->
                    </div>
                    <!--end::Content-->
                  </div>
                  <!--end::Menu 1-->
                  <!--begin::Filter-->
                                                      <button @click="toggleMenuFilter" type="button" class="btn btn-light-primary me-3 " data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                      <i class="ki-duotone ki-filter fs-2">
                                                          <span class="path1"></span>
                                                          <span class="path2"></span>
                                                      </i>{{ this.$NOLIMITResource[this.dataUser.language].FilterSearch }}</button>
                                                      <!--begin::Menu 1-->
                                                      <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px  show-dropfilter" :class="{show:this.onShowMenuFilter == true}"  data-kt-menu="true" id="kt-toolbar-filter" data-select2-id="select2-data-kt-toolbar-filter" style="">
                                                          <!--begin::Header-->
                                                          <div class="px-7 py-5">
                                                              <div class="fs-4 text-gray-900 fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].FilterOptions }}</div>
                                                          </div>
                                                          <!--end::Header-->
                                                          <!--begin::Separator-->
                              
                                                          <div class="separator border-gray-200"></div>
                                                          <!--end::Separator-->
                              <form  
                                  action=""
                                  @submit.prevent="onFilterSearchFirst">
                                                          <!--begin::Content-->
                                                          <div class="px-7 py-5" data-select2-id="select2-data-128-13oj">
                                <!--begin::Input group-->
                                <div>
                                  <label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].StartDate }}:</label>
                                  <v-date-picker class="inline-block h-full" v-model="dateStart" :timezone="timezone2">
                                    <template v-slot="{ inputValue, togglePopover }">
                                      <div class="flex items-center">
                                        <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].StartDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                                      </div>
                                    </template>
                                  </v-date-picker>
                                </div>
                                <!--end::Input group-->
  
                                <!--begin::Input group-->
                                <div>
                                  <label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].EndDate }}:</label>
                                  <v-date-picker class="inline-block h-full" v-model="dateEnd" :timezone="timezone1">
                                    <template v-slot="{ inputValue, togglePopover }">
                                      <div class="flex items-center">
                                        <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].EndDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                                      </div>
                                    </template>
                                  </v-date-picker>
                                </div>
                                <!--end::Input group-->
  
                                                         
                                                              <!--begin::Actions-->
                                                              <div class="d-flex justify-content-end">
                                                                  <button @click="onResetFilter" type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">{{ this.$NOLIMITResource[this.dataUser.language].Reset }}</button>
                                                                  <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">{{ this.$NOLIMITResource[this.dataUser.language].Filter }}</button>
                                                              </div>
                                                              <!--end::Actions-->
                                                          </div>
                                                          <!--end::Content-->
                            </form>
                                                      </div>
                                                      <!--end::Menu 1-->
                          <!--end::Filter-->
                </div>
                <!--end::Toolbar-->
                <!--begin::Group actions-->
                <div
                  class="d-flex justify-content-end align-items-center d-none"
                  data-kt-customer-table-toolbar="selected"
                >
                  <div class="fw-bold me-5">
                    <span
                      class="me-2"
                      data-kt-customer-table-select="selected_count"
                    ></span
                    >Selected
                  </div>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-kt-customer-table-select="delete_selected"
                  >
                    Delete Selected
                  </button>
                </div>
                <!--end::Group actions-->
              </div>
              
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-0" style="margin-top: 20px;">
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-dashed fs-6 gy-5"
                  id="kt_customers_table"
                >
                  <thead>
                    <tr
                      class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                    >
                      <template v-for="(column, index) in columns" :key="index" >
                        <th class="min-w-125px" :class="{
                          sorting_desc: this.sortingNumber == index,
                          sorting_asc: this.sortingAsc == index,
                        }" :style="{ 'width': column.width + 'px !important'}">
                          
                            <div @mousedown="startResize($event, index)"><span
                              @click="
                                onSorting(index, column.property, 'changeSort')
                              "
                              >{{ column.label }}</span
                            ></div>
                          
                        </th>
                      </template>
                      <th class="text-end min-w-70px"></th>
                    </tr>
                  </thead>
                  <div class="loading" v-if="onLoading">
                      <span  class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </span>
                    </div>
                    <div id="noData" v-if="!this.onLoading && this.hasData == false">{{ this.$NOLIMITResource[this.dataUser.language].EmptyData }}</div> 
                  <tbody v-if="!this.onLoading && this.hasData == true"  class="fw-semibold text-gray-600">
                    
                    
                    <tr v-for="topupThai in topupThais" :key="topupThai.Id">
                        <td>{{ formatDateTime(topupThai.createdDate) }}</td>
                      <td>
                        <a
                          class="text-gray-800 text-hover-primary mb-1"
                          >{{ topupThai.facebookAdsId }}</a
                        >
                      </td>
                      <td>{{ topupThai.name }}</td>
                      <td>{{ topupThai.customerId }}</td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.amount)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.amountPayAble)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.fee)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.bankFee)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.amountBankFee)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.profitFee)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.totalProfit)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.partProfit)}}</a></td>
                      <td><a  class="text-gray-600 text-hover-primary mb-1">{{formatCurrency(topupThai.paidForNolimit)}}</a></td>
          
                      
                     
                      
                   
                    </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div
                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                  >
                    <div class="dataTables_length" id="kt_customers_table_length">
                      <label>
                        <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="kt_customers_table_paginate"
                    >
                      <ul v-if="this.totalPage >= 4" class="pagination">
                        <li
                          class="paginate_button page-item previous"
                          :class="{ disabled: this.pageNumber == 1 }"
                          id="kt_customers_table_previous"
                        >
                          <a
                            @click="prevPage"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            ><i class="previous"></i
                          ></a>
                        </li>
                        <li
                          v-for="index in 4"
                          :key="index"
                          class="paginate_button page-item"
                          :class="{ active: pageNumber == this.firstPage + index }"
                        >
                          <a
                            @click="selectPage(this.firstPage + index)"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >{{ this.firstPage + index }}</a
                          >
                        </li>
                        <li
                          class="paginate_button page-item next"
                          :class="{ disabled: this.pageNumber == this.totalPage }"
                          id="kt_customers_table_next"
                        >
                          <a
                            @click="nextPage"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="5"
                            tabindex="0"
                            class="page-link"
                            ><i class="next"></i
                          ></a>
                        </li>
                      </ul>
                      <ul v-if="this.totalPage < 4" class="pagination">
                        <li
                          class="paginate_button page-item previous"
                          :class="{ disabled: this.pageNumber == 1 }"
                          id="kt_customers_table_previous"
                        >
                          <a
                            @click="prevPage"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            ><i class="previous"></i
                          ></a>
                        </li>
                        <li
                          v-for="index in totalPage"
                          :key="index"
                          class="paginate_button page-item"
                          :class="{ active: pageNumber == this.firstPage + index }"
                        >
                          <a
                            @click="selectPage(this.firstPage + index)"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >{{ this.firstPage + index }}</a
                          >
                        </li>
                        <li
                          class="paginate_button page-item next"
                          :class="{ disabled: this.pageNumber == this.totalPage }"
                          id="kt_customers_table_next"
                        >
                          <a
                            @click="nextPage"
                            aria-controls="kt_customers_table"
                            data-dt-idx="5"
                            tabindex="0"
                            class="page-link"
                            ><i class="next"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!--end::Table-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
            <!--begin::Modals-->
          </div>
          <!--end::Content container-->
        </div>
  
        <!--end::Content-->
      </div>
    </template>
    <style scoped>
    .table {
      overflow: scroll;
    }
    .loading{
      height: 500px;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }
    tr td {
      text-align: start;
    }
    .menu-sub-dropdown {
      position: absolute;
    }
    .block {
      display: block;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .show-dropfilter{
      z-index: 107; position: absolute; inset: 0px 0px auto auto; margin: 0px;
      top: 90px;
      right: 20px;
      text-align: start;
    }
    </style>
    <script>
    import { mapActions ,mapMutations,mapGetters} from "vuex";
    import router from '@/router/router.js';
    export default {
      name: "TopupThai",
      data() {
        return {
            isSupport:true,
          dataUser:{language:"VN"},
          currentIndex: null,
          currentProperty: "",
          modeSort:"",
          sortingAsc: null,
          sortingNumber: null,
          isResizing: false,
          initialX: 0,
          initialWidth: 0,
          resizingCol: null,
          columns: [
              { label: this.$NOLIMITResource["VN"].TopupThai.CreatedDate, width: 300, property: "CreatedDate" },
              { label: this.$NOLIMITResource["VN"].TopupThai.FacebookAdsId, width: 250, property: "FacebookAdsId"  },
              { label: this.$NOLIMITResource["VN"].TopupThai.Name, width: 300, property: "Name" },
              { label: this.$NOLIMITResource["VN"].TopupThai.CustomerID, width: 200, property: "CustomerID" },
              { label: this.$NOLIMITResource["VN"].TopupThai.Amount, width: 200, property: "Amount" },
              { label: this.$NOLIMITResource["VN"].TopupThai.AmountPayAble, width: 200, property: "AmountPayAble" },
              { label: this.$NOLIMITResource["VN"].TopupThai.Fee, width: 200, property: "Fee" },
              { label: this.$NOLIMITResource["VN"].TopupThai.BankFee, width: 200, property: "BankFee" },
              { label: this.$NOLIMITResource["VN"].TopupThai.AmountBankFee, width: 200, property: "AmountBankFee" },
              { label: this.$NOLIMITResource["VN"].TopupThai.ProfitFee, width: 200, property: "ProfitFee" },
              { label: this.$NOLIMITResource["VN"].TopupThai.TotalProfit, width: 200, property: "TotalProfit" },
              { label: this.$NOLIMITResource["VN"].TopupThai.PartProfit, width: 200, property: "PartProfit" },
              { label: this.$NOLIMITResource["VN"].TopupThai.PaidForNolimit, width: 200, property: "PaidForNolimit" },
              
              
          ],
  
          numberPerPage:[10,25,50,100],
          onLoading:true,
          topupThais: [],
          onShowMenu: false,
          currenOnshowId: 0,
          isShowModal: false,
          currentBalanceUser: 0,
          currentTotalMoneyUser: 0,
          isShowModalUpdateUser: false,
          changeMoney: 0,
          formMode: "",
          pageSize: 10,
          pageNumber: 1,
          totalPage: 0,
          firstPage: 0,
          inputValue: "",
          userId:0,
          isAdmin:true,
          hasData:true,
          status:"",
        dateStart: null,
        dateEnd: null,
        timezoneIndex: 20,
          timezoneIndex1: 1,
          timezones: [
            'Pacific/Midway',      // -11
            'Pacific/Niue',        // -11
            'Pacific/Honolulu',    // -10
            'Pacific/Pago_Pago',   // -11
            'America/Anchorage',   // -9
            'America/Los_Angeles', // -8
            'America/Tijuana',     // -8
            'America/Denver',      // -7
            'America/Phoenix',     // -7
            'America/Chicago',      // -6
            'America/Mexico_City', // -6
            'America/Regina',      // -6
            'America/New_York',    // -5
            'America/Caracas',     // -4.5
            'America/Halifax',     // -4
            'America/Argentina/Buenos_Aires', // -3
            'America/Sao_Paulo',   // -3
            'Atlantic/Azores',     // -1
            'Atlantic/Cape_Verde', // -1
            'UTC',                 // 0
            'Europe/London',       // +0
            'Europe/Paris',        // +1
            'Europe/Istanbul',     // +3
            'Europe/Moscow',       // +3
            'Asia/Dubai',          // +4
            'Asia/Yekaterinburg',  // +5
            'Asia/Almaty',         // +6
            'Asia/Colombo',        // +6
            'Asia/Bangkok',        // +7
            'Asia/Ho_Chi_Minh',    // +7
            'Asia/Jakarta',        // +7
            'Asia/Taipei',         // +8
            'Asia/Singapore',      // +8
            'Asia/Kuala_Lumpur',   // +8
            'Asia/Hong_Kong',      // +8
            'Asia/Ulaanbaatar',    // +8
            'Asia/Pyongyang',      // +9
            'Asia/Tokyo',          // +9
            'Australia/Darwin',    // +9.5
            'Australia/Adelaide',  // +10
            'Australia/Sydney',    // +10
            'Pacific/Guam',        // +10
            'Australia/Brisbane',  // +10
            'Asia/Vladivostok',    // +10
            'Pacific/Fiji',        // +12
            'Pacific/Tongatapu',   // +13
            'Pacific/Apia',        // +13
          ],
        };
      },
      computed:{
        ...mapGetters("listModule", [
        "onShowMenuFilter",
        "formatCurrency",
        "formatDateTime",
      ]),
      timezone2() {
        return this.timezones[this.timezoneIndex];
      },
      timezone1() {
        return this.timezones[this.timezoneIndex1];
      },
      attributes() {
        return[ {
          highlight: true,
          dates: this.dateStart,
        }];
      },
    },
      created() {
          this.$apiService
        .getUserProfile()
        .then((res) => {
          if(res.data.data.language == "VN" || res.data.data.language == "EN"){
            this.dataUser = res.data.data;
          }else{
              this.dataUser = res.data.data;
              this.dataUser.language = "VN";
          }
              this.userId = res.data.data.userId
              this.isAdmin = res.data.data.isAdmin;
            this.isSupport = res.data.data.isSupport;
              if(res.data.data.isAdmin == false && res.data.data.isSupport != true){
                  this.isAdmin=false;
                  this.isSupport=false;
                  router.push({ name: 'ErrorNotFound' });
              }
              this.$apiService.getAllTopupThaiByAdmin(this.pageNumber, this.pageSize,"")
                  .then((res) => {
                  setTimeout(()=>this.onLoading=false,1000)
                  this.topupThais = res.data.data;
                  if(res.data.data.length == 0){
                  this.hasData = false;
                }
                  this.totalPage = res.data.pageData.pageCount;
                  })
                  .catch((error) => {
                  console.error("Lỗi khi lấy dữ liệu:", error);
              });

          })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
      },
      watch: {
        dataUser:{
        handler: function() {
          this.columns = [
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.CreatedDate, width: 300, property: "CreatedDate" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.FacebookAdsId, width: 250, property: "FacebookAdsId"  },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.Name, width: 300, property: "Name" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.CustomerID, width: 200, property: "CustomerID" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.Amount, width: 200, property: "Amount" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.AmountPayAble, width: 200, property: "AmountPayAble" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.Fee, width: 200, property: "Fee" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.BankFee, width: 200, property: "BankFee" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.AmountBankFee, width: 200, property: "AmountBankFee" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.ProfitFee, width: 200, property: "ProfitFee" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.TotalProfit, width: 200, property: "TotalProfit" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.PartProfit, width: 200, property: "PartProfit" },
              { label: this.$NOLIMITResource[this.dataUser.language].TopupThai.PaidForNolimit, width: 200, property: "PaidForNolimit" },
              
              
          ]
        }
      },
      pageSize() {
        this.reDisplay();
      },
    },
      methods: {
        ...mapActions("listModule", ["toggleMenuFilter"]),
        ...mapMutations("listModule", ["closeMenuFilter"]),
        choosePageSize(event) {
        this.pageSize = event.target.value;
        this.reDisplay();
      },
        selectPage(index) {
          this.pageNumber = index;
          if (this.totalPage >= 4) {
            if (index == this.totalPage) {
              this.firstPage = index - 4;
            } else if (index >= 3) {
              this.firstPage = index - 3;
            } else {
              this.firstPage = 0;
            }
          }
          if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
          } else if (this.sortingNumber != null || this.sortingAsc != null) {
            this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
          } else {
            this.onEnterSearch();
          }
        },
        reDisplay() {
          this.hasData = true;
          this.$apiService
            .getAlltopupThaiByAdmin(this.pageNumber, this.pageSize)
            .then((res) => {
              this.onLoading=true
              setTimeout(()=>this.onLoading=false,1000)
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                  this.hasData = false;
                }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        },
        /**
         * Chọn next trang tiếp theo trong mục phân trang
         * Author: ndanh (20/09/2023)
         */
        nextPage() {
          if (this.pageNumber != this.totalPage) {
            this.pageNumber += 1;
            if (this.totalPage >= 4) {
              if (this.pageNumber == this.totalPage) {
                this.firstPage = this.pageNumber - 4;
              } else if (this.pageNumber >= 3) {
                this.firstPage = this.pageNumber - 3;
              } else {
                this.firstPage = 0;
              }
            }
          }
          if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
          } else if (this.sortingNumber != null || this.sortingAsc != null) {
            this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
          } else {
            this.onEnterSearch();
          }
        },
        /**
         * Chọn prev trang trước đó trong mục phân trang
         * Author: ndanh (20/09/2023)
         */
        prevPage() {
          if (this.pageNumber != 1) {
            this.pageNumber -= 1;
            if (this.totalPage >= 4) {
              if (this.pageNumber == this.totalPage) {
                this.firstPage = this.pageNumber - 4;
              } else if (this.pageNumber >= 3) {
                this.firstPage = this.pageNumber - 3;
              } else {
                this.firstPage = 0;
              }
            }
          }
          if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
          } else if (this.sortingNumber != null || this.sortingAsc != null) {
            this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
          } else {
            this.onEnterSearch();
          }
        },
  
  
      onEnterSearch() {
        this.hasData = true;
        if (this.inputValue == "") {
          this.reDisplay();
        } else {
          this.$apiService
            .getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              this.currentProperty,
              this.modeSort,
            )
            .then((res) => {
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                  this.hasData = false;
                }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      },
      onEnterSearchFirst() {
        this.hasData = true;
        if (this.inputValue == "") {
          this.reDisplay();
        } else {
          this.pageNumber = 1;
          this.firstPage = 0;
          this.$apiService
            .getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              this.currentProperty,
              this.modeSort,
            )
            .then((res) => {
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                  this.hasData = false;
                }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      },
      onFilterSearch(){
          this.hasData = true;
          if((this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart) && this.status == "" && this.inputValue == ""){
            this.reDisplay();
          }else if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){
            this.$apiService.getAlltopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              this.currentProperty,
              this.modeSort,
            ).then((res)=>{
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
          }else if(this.inputValue == ""){
            const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
            this.$apiService.getAlltopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              "",
              this.currentProperty,
              this.modeSort,
            ).then((res)=>{
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
          }else{
            const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
            this.$apiService.getAlltopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              this.currentProperty,
              this.modeSort,
            ).then((res)=>{
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
          }
          this.closeMenuFilter();
        },
        onFilterSearchFirst(){
          this.hasData = true;
          if((this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart) && this.status == "" && this.inputValue == ""){
            this.reDisplay();
          }else{ 
            this.pageNumber = 1;
            this.firstPage = 0;
            if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){
            this.$apiService.getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              this.currentProperty,
              this.modeSort,
            ).then((res)=>{
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
          }else if(this.inputValue == ""){
            const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
            this.$apiService.getAllTopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              "",
              this.currentProperty,
              this.modeSort,
            ).then((res)=>{
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
          }else{
            const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
            this.$apiService.getAllTopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              this.currentProperty,
              this.modeSort,
            ).then((res)=>{
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
          }
        }
          this.closeMenuFilter();
        },
        convertToEndOfDay(date) {
        date.setHours(23, 59, 59, 999);
  
        // Lấy các thành phần ngày giờ
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
  
        // Tạo chuỗi ISO
        const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        return isoString;
        },
        onResetFilter(){
          this.status="";
          this.dateStart = null;
          this.dateEnd = null;
        },  
        startResize(event, index) {
          if (event.offsetX > event.target.offsetWidth - 10) {
                  this.isResizing = true;
                  this.initialX = event.clientX;
                  this.initialWidth = this.columns[index].width;
                  this.resizingCol = index;
  
                  document.addEventListener('mousemove', this.handleMouseMove);
                  document.addEventListener('mouseup', this.handleMouseUp);
          } 
        },
        handleMouseMove(event) {
            if (this.isResizing && this.resizingCol !== null) {
                const delta = event.clientX - this.initialX;
                const newWidth = Math.max(50, this.initialWidth + delta);
                this.columns[this.resizingCol] = { ...this.columns[this.resizingCol], width: newWidth };
            }
        },
        handleMouseUp() {
            this.isResizing = false;
            this.initialX = 0;
            this.initialWidth = 0;
            this.resizingCol = null;
  
            document.removeEventListener('mousemove', this.handleMouseMove);
            document.removeEventListener('mouseup', this.handleMouseUp);
        },
        onSorting(index, property, mode) {
        this.currentIndex = index;
        this.currentProperty = property;
        if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){
        if (this.sortingNumber == index && mode == "changeSort") {
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingNumber == index && mode == "selectPage") {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "changeSort") {
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "selectPage") {
          this.sortingAsc = null;
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllTopupThaiByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      }else {
        const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          if (this.sortingNumber == index && mode == "changeSort") {
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAllTopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingNumber == index && mode == "selectPage") {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllTopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "changeSort") {
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllTopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "selectPage") {
          this.sortingAsc = null;
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAlltopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAlltopupThaiByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.topupThais = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      
      }
      },
      exportCSV(){
        let data = [];
        for (let i = 0; i < this.topupThais.length; i++){

  
          let item = {};
          if(this.dataUser.language == "EN"){
             item = {
                'Date': this.formatDateTime(this.topupThais[i].createdDate),
              'ID TK': this.topupThais[i].facebookAdsId,
              'Name TK': this.topupThais[i].name,
              'Customer ID': this.topupThais[i].customerId,
              'Deposit Amount': '$'+this.topupThais[i].amount,
              'Amount Payable' : '$'+this.topupThais[i].amountPayAble,
              'Fee': this.topupThais[i].fee,
              'BankFee': '$'+this.topupThais[i].bankFee,
              'AmountBankFee': this.topupThais[i].amountBankFee,
              'ProfitFee': this.topupThais[i].profitFee,
              'TotalProfit': '$'+this.topupThais[i].totalProfit,
              'PartProfit': '$'+this.topupThais[i].partProfit,
              'PaidForNolimit': '$'+this.topupThais[i].paidForNolimit,
              
            }
          }else{
             item = {
                'Date': this.formatDateTime(this.topupThais[i].createdDate),
              'ID TK': this.topupThais[i].facebookAdsId,
              'Name TK': this.topupThais[i].name,
              'Customer ID': this.topupThais[i].customerId,
              'Deposit Amount': '$'+this.topupThais[i].amount,
              'Amount Payable' : '$'+this.topupThais[i].amountPayAble,
              'Fee': this.topupThais[i].fee,
              'BankFee': '$'+this.topupThais[i].bankFee,
              'AmountBankFee': this.topupThais[i].amountBankFee,
              'ProfitFee': this.topupThais[i].profitFee,
              'TotalProfit': '$'+this.topupThais[i].totalProfit,
              'PartProfit': '$'+this.topupThais[i].partProfit,
              'PaidForNolimit': '$'+this.topupThais[i].paidForNolimit,
            }
          }
            
  
            data.push(item);
        }
        const csvContent1 = this.convertToCSV(data);
        var BOM = "\uFEFF";
        var csvContent = BOM + csvContent1;
        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download','export_deposit.csv');
        link.click();
      },
      convertToCSV(data){
          const headers = Object.keys(data[0]);
          const rows = data.map(obj => headers.map(header => obj[header]));
          const headerRow = headers.join(',');
          const csvRows = [headerRow, ...rows.map(row=> row.join(','))];
          return csvRows.join('\n');
      }
      }
  }
    </script>
    