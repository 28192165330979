<template>
  <div v-if="this.isAdmin == false"> {{ this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div>
  <!--begin::Content wrapper-->
  <div v-else class="d-flex flex-column flex-column-fluid" style="align-items: start;margin-left: 40px;">
    <!--begin::Modal - change money-->
        <div
          class="modal fade show"
          :class="{ block: onShowAddAccount }"
          id="kt_modal_new_card"
          role="dialog"
          tabindex="-1"
          aria-modal="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2> {{ this.$NOLIMITResource[this.dataUser.language].Setting.AddBank }}</h2>
                <div
                  @click="closeModalAddAccount"
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form
                  id="kt_modal_new_card_form"
                  class="form"
                  action=""
                  @submit.prevent="addAccount"
                >
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].Setting.LoginAccount }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].Setting.LoginAccount"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.accountAdd.bankUser"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].Setting.LoginPassword }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].Setting.LoginPassword"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.accountAdd.bankPass"
                    />
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Setting.NameBank }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].Setting.NameBank"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.accountAdd.name"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Setting.NumberBank }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].Setting.NumberBank"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.accountAdd.value"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label 
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Setting.Bank }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].Setting.Bank"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                     
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="this.accountAdd.bank"
                    />
                  </div>
                  <!--end::Input group-->
                
                  <!--begin::Actions-->
                  <div class="text-center pt-15">
                    <button
                      @click="closeModalAddAccount"
                      type="reset"
                      id="kt_modal_new_card_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_card_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label"> {{ this.$NOLIMITResource[this.dataUser.language].Setting.AddAccount }}</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - change money-->
        <div style="display: flex;">
    <div data-v-65b6fa91="" class="card mb-5 mb-xl-10" style="width: 590px;margin-bottom: 0px !important;margin-right: 20px;">
      <!--begin::Card header-->
      <div
        data-v-65b6fa91=""
        class="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <!--begin::Card title-->
        <div data-v-65b6fa91="" class="card-title m-0">
          <h3 data-v-65b6fa91="" class="fw-bold m-0">{{ this.$NOLIMITResource[this.dataUser.language].Setting.SettingExchangeRate }}</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header--><!--begin::Content-->
      <div
        data-v-65b6fa91=""
        id="kt_account_settings_profile_details"
        class="collapse show"
      >
      
        <!--begin::Form-->
        <form
          data-v-65b6fa91=""
          id="kt_account_profile_details_form"
          class="form"
          action=""
          @submit.prevent="confirmUpdateCurrencySubmit"
        >
          <!--begin::Card body-->
          <div data-v-65b6fa91="" class="card-body border-top p-9">
            <!--begin::Input group-->
            <div data-v-65b6fa91="" class="row mb-6">
              <!--begin::Col-->
              <div data-v-65b6fa91="" class="col-lg-8 fv-row">
                <input
                  data-v-65b6fa91=""
                  type="text"
                  name="email"
                  v-model="this.currencyConvert.value"
                  class="form-control form-control-lg form-control-solid"
                />
              </div>
              <!--end::Col-->
            </div>
          </div>
          <!--end::Card body--><!--begin::Actions-->
          <div
            data-v-65b6fa91=""
            class="card-footer d-flex justify-content-end py-6 px-9"
          >
            <button
              data-v-65b6fa91=""
              type="submit"
              class="btn btn-primary"
              id="kt_account_profile_details_submit"
            >
            {{ this.$NOLIMITResource[this.dataUser.language].Save }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--begin::Toolbar-->
    <div data-v-65b6fa91="" class="card mb-5 mb-xl-10" style="width: 590px;margin-bottom: 0px !important;">
      <!--begin::Card header-->
      <div
        data-v-65b6fa91=""
        class="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <!--begin::Card title-->
        <div data-v-65b6fa91="" class="card-title m-0">
          <h3 data-v-65b6fa91="" class="fw-bold m-0">Cài đặt key Binance</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header--><!--begin::Content-->
      <div
        data-v-65b6fa91=""
        id="kt_account_settings_profile_details"
        class="collapse show"
      >
      
        <!--begin::Form-->
        <form
          data-v-65b6fa91=""
          id="kt_account_profile_details_form"
          class="form"
          action=""
          @submit.prevent="confirmUpdateBinanceKeySubmit"
        >
          <!--begin::Card body-->
          <div data-v-65b6fa91="" class="card-body border-top p-9" style="padding: 10px 20px !important;">
            <!--begin::Input group-->
            <div data-v-65b6fa91="" class="row mb-6" style="height: 35px;">
              <!--begin::Col-->
              <div data-v-65b6fa91="" class="col-lg-8 fv-row" style="display: flex;align-items: center;">
                <span style="width: 80px;">ApiKey</span>
                <input
                  data-v-65b6fa91=""
                  type="text"
                  name="apiKey"
                  v-model="this.settingBinance.apikey"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="apiKey"
                  style="display: inline;margin-left: 20px"
                />
              </div>
              <!--end::Col-->
              
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div data-v-65b6fa91="" class="row mb-6" style="height: 35px;">
              <!--begin::Col-->
              <div data-v-65b6fa91="" class="col-lg-8 fv-row" style="display: flex;align-items: center;">
                <span style="width: 80px;">SecretKey</span>
                <input
                  data-v-65b6fa91=""
                  type="text"
                  name="secretKey"
                  v-model="this.settingBinance.secretKey"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="secretKey"
                  style="display: inline;margin-left: 20px"
                />
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
             <!--begin::Input group-->
             <div data-v-65b6fa91="" class="row mb-6" style="height: 35px;margin-bottom: 10px !important">
              <!--begin::Col-->
              <div data-v-65b6fa91="" class="col-lg-8 fv-row" style="display: flex;align-items: center;">
                <span style="width: 80px;">BinanceID</span>
                <input
                  data-v-65b6fa91=""
                  type="text"
                  name="secretKey"
                  v-model="this.settingBinance.binanceId"
                  class="form-control form-control-lg form-control-solid"
                  placeholder="secretKey"
                  style="display: inline;margin-left: 20px"
                />
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
          </div>
          <!--end::Card body--><!--begin::Actions-->
          <div
            data-v-65b6fa91=""
            class="card-footer d-flex justify-content-end py-6 px-9"
          >
            <button
              data-v-65b6fa91=""
              type="submit"
              class="btn"
              id="kt_account_profile_details_submit"
              style="background-color: #fcd535;color: #fff;"
            >
            {{ this.$NOLIMITResource[this.dataUser.language].Save }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--begin::Toolbar-->
  </div>
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
     
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div style="padding-left: 0px !important;" id="kt_app_content_container" class="app-container container-xxl">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card header-->
          <div class="card-header border-0 pt-6">
            <!--begin::Card title-->
            <div class="card-title"><h1
            class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          >
          {{ this.$NOLIMITResource[this.dataUser.language].Setting.SettingAccountBank }}
          </h1></div>
          <div style="position: absolute;right: 29.25px;">
            <button @click="openAddAccount" class="btn btn-primary add-account">  {{ this.$NOLIMITResource[this.dataUser.language].Setting.AddBank }} </button>
          </div>
          

            <!--begin::Card title-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar">
              <!--begin::Toolbar-->
              <div
                class="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                  data-kt-menu="true"
                  id="kt-toolbar-filter"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-4 text-gray-900 fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Separator-->
                  <!--begin::Content-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Month:</label
                      >
                      <!--end::Label-->
                      <!--begin::Input-->
                      <select
                        class="form-select form-select-solid fw-bold"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-customer-table-filter="month"
                        data-dropdown-parent="#kt-toolbar-filter"
                      >
                        <option></option>
                        <option value="aug">August</option>
                        <option value="sep">September</option>
                        <option value="oct">October</option>
                        <option value="nov">November</option>
                        <option value="dec">December</option>
                      </select>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Payment Type:</label
                      >
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div
                        class="d-flex flex-column flex-wrap fw-semibold"
                        data-kt-customer-table-filter="payment_type"
                      >
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="all"
                            checked="checked"
                          />
                          <span class="form-check-label text-gray-600"
                            >All</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="visa"
                          />
                          <span class="form-check-label text-gray-600"
                            >Visa</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="mastercard"
                          />
                          <span class="form-check-label text-gray-600"
                            >Mastercard</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="american_express"
                          />
                          <span class="form-check-label text-gray-600"
                            >American Express</span
                          >
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="reset"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="filter"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Menu 1-->
                <!--end::Filter-->
              </div>
              <!--end::Toolbar-->
              <!--begin::Group actions-->
              <div
                class="d-flex justify-content-end align-items-center d-none"
                data-kt-customer-table-toolbar="selected"
              >
                <div class="fw-bold me-5">
                  <span
                    class="me-2"
                    data-kt-customer-table-select="selected_count"
                  ></span
                  >Selected
                </div>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-kt-customer-table-select="delete_selected"
                >
                  Delete Selected
                </button>
              </div>
              <!--end::Group actions-->
            </div>
            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body pt-0">
            <!--begin::Table-->
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="min-w-125px"> {{ this.$NOLIMITResource[this.dataUser.language].Setting.NameBank }}</th>
                  <th class="min-w-125px">{{ this.$NOLIMITResource[this.dataUser.language].Setting.NumberBank }}</th>
                  <th class="min-w-125px">{{ this.$NOLIMITResource[this.dataUser.language].Setting.Bank }}</th>
                  <th class="min-w-125px">{{ this.$NOLIMITResource[this.dataUser.language].Setting.LoginAccount }}</th>
                  <th class="min-w-125px">{{ this.$NOLIMITResource[this.dataUser.language].Setting.LoginPassword }}</th>
                  <th class="min-w-125px">{{ this.$NOLIMITResource[this.dataUser.language].Setting.Prioritize }}</th>
                  <th class="min-w-125px"> {{ this.$NOLIMITResource[this.dataUser.language].Setting.StatusBank }}</th>
                  <th class="text-end min-w-70px">{{ this.$NOLIMITResource[this.dataUser.language].Feature }}</th>
                </tr>
              </thead>
              <div class="loading" v-if="onLoading">
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div id="noData" v-if="!this.onLoading && this.hasData == false">
                {{ this.$NOLIMITResource[this.dataUser.language].EmptyData }}
              </div>
              <tbody
                v-if="!this.onLoading && this.hasData == true"
                class="fw-semibold text-gray-600"
              >
                <tr
                  v-for="account in accountSetting"
                  :key="account.settingId"
                >
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ account.name }}</a
                    >
                  </td>
                  <td>
                    <a class="text-gray-600 text-hover-primary mb-1">{{
                      account.value
                    }}</a>
                  </td>
                  <td>
                    {{ account.bank }}
                  </td>
                  <td>
                    {{ account.bankUser }}
                  </td>
                  <td>
                    {{ account.bankPass }}
                  </td>
                  <td>
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                        v-if="account.bankPrioritize"
                        :class="`badge badge-light-success`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].Setting.StatusPrioritize }}
                      </div>
                      <div v-else :class="`badge badge-light-danger`">
                        
                      </div>
                    </a>
                  </td>
                  <td>
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                        v-if="account.status"
                        :class="`badge badge-light-success`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].Setting.StatusActive }}
                      </div>
                      <div v-else :class="`badge badge-light-danger`">
                        {{ this.$NOLIMITResource[this.dataUser.language].Setting.StatusInactive }}
                      </div>
                    </a>
                  </td>
                  <td class="text-end" style="display: flex; height: 75px;">
                    <a v-if="account.status == false && account.bankPrioritize == false" @click="selectAccount({status:true},account.settingId)"
                    style="margin-right: 10px;"
                      class="btn-sm btn btn-light-success"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      >{{ this.$NOLIMITResource[this.dataUser.language].Setting.TurnOnUse }}</a>
                    <a v-if="account.status == true && account.bankPrioritize == false" @click="selectAccount({status:false},account.settingId)"
                    style="margin-right: 10px;width: 115px;"
                      class="btn-sm btn btn-light-danger"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      >{{ this.$NOLIMITResource[this.dataUser.language].Setting.TurnOffUse }}</a>
                      <a v-if="account.status == true && account.bankPrioritize == false" @click="updateBankPrioritize({bankPrioritize:true},account.settingId)"
                      style="margin-right: 10px;width: 130px;"
                        class="btn-sm btn btn-light-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        >{{ this.$NOLIMITResource[this.dataUser.language].Setting.MakeItPriority }}</a>
                    <a v-if="account.bankPrioritize == false" @click="deleteAccountConfirm(account.settingId,account.status)"
                      class="btn btn-light-danger btn-sm"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      >{{ this.$NOLIMITResource[this.dataUser.language].Setting.Delete }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div
                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
              >
                <div class="dataTables_length" id="kt_customers_table_length">
                  <label>
                    <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                <div
                  class="dataTables_paginate paging_simple_numbers"
                  id="kt_customers_table_paginate"
                >
                  <ul v-if="this.totalPage >= 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in 4"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                        aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                  <ul v-if="this.totalPage < 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in totalPage"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                        aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
      </div>
      <!--end::Content container-->
    </div>

    <!--end::Content-->
  </div>

</template>
    <style scoped>
    
.table {
  overflow: scroll;
  height: auto !important;
}
@media only screen and (max-width: 5000px) {
.table tbody td:last-child, .table thead th:last-child {
width:325px !important;
}
}
th:last-child{
  height: 100% !important;
    border-bottom: none;
} 
.loading {
  height: 500px;
  width: 800%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
tr td {
  text-align: start;
}
.menu-sub-dropdown {
  position: absolute;
}
.block {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.add-account{
    height: 43px;
}
table{
  display: table;
  height: auto;
}
</style>
    <script>
import router from '@/router/router.js';
// import Swal from "sweetalert2";
import { mapActions } from "vuex";
export default {
    
  name: "SettingGeneral",
  data() {
    return {
      settingBinance:{},
      dataUser:{language:"VN"},
      numberPerPage:[10,25,50,100],
        accountAdd:{
            name:'',
            value:'',
            bank:''
        },
      onLoading: true,
      accountSetting: [],
      onShowAddAccount:false,
      currenOnshowId: 0,
      isShowModal: false,
      currentBalanceUser: 0,
      currentTotalMoneyUser: 0,
      isShowModalUpdateUser: false,
      changeMoney: 0,
      formMode: "",
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      firstPage: 0,
      inputValue: "",
      userId: 0,
      isAdmin: true,
      hasData: true,
      currencyConvert:{
        value:0
      },
      errors:{
          fee:''
        },
        isValidate:false,
    };
  },
  watch: {
    pageSize() {
      this.reDisplay();
    },
  },

  created() {
    this.$apiService
      .getUserProfile()
      .then((res) => {  
        if(res.data.data.language == "VN" || res.data.data.language == "EN"){
          this.dataUser = res.data.data;
      }else{
          this.dataUser = res.data.data;
          this.dataUser.language = "VN";
      }
        this.userId = res.data.data.userId;
        if (res.data.data.isAdmin == false) {
          this.isAdmin = false;
          router.push({ name: 'ErrorNotFound' });
        }
        this.$apiService
          .getAllSettingAccount(this.pageNumber, this.pageSize)
          .then((res) => {
            setTimeout(() => (this.onLoading = false), 1000);
            this.accountSetting = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });
    this.$apiService.getSettingById(4)
    .then((res) =>{
        this.currencyConvert.value = res.data.value
    })  
    this.$apiService.getSettingBinanceById(1).then((res)=>{
      this.settingBinance = res.data;
    })
  },

  methods: {

    ...mapActions("listModule", ["swalSuccess","swalError","swalWarning"]),
    validate()
		{
			this.isValidate = true;
			this.errors={
          fee:''
			};
			if(!this.currencyConvert.value){
				this.errors.fee = this.$NOLIMITResource[this.dataUser.language].Setting.ErrorValidateEmpryPriority
				this.isValidate = false;
			}else if(!this.validFee(this.currencyConvert.value)){
				this.errors.fee = this.$NOLIMITResource[this.dataUser.language].Setting.ErrorValidateNumber
				this.isValidate = false;
			}
			return this.isValidate;
		},
		validFee(fee){
			var re = /^[0-9]+(\.[0-9]+)?$/;
			return re.test(fee);
		},
    choosePageSize(event) {
      this.pageSize = event.target.value;
      this.reDisplay();
    },
    selectPage(index) {
      this.pageNumber = index;
      if (this.totalPage >= 4) {
        if (index == this.totalPage) {
          this.firstPage = index - 4;
        } else if (index >= 3) {
          this.firstPage = index - 3;
        } else {
          this.firstPage = 0;
        }
      }
      this.reDisplay();
    },
    reDisplay() {
      this.hasData = true;
      this.$apiService
        .getAllSettingAccount(this.pageNumber, this.pageSize)
        .then((res) => {
          this.onLoading = true;
          setTimeout(() => (this.onLoading = false), 1000);
          this.accountSetting = res.data.data;
          if (res.data.data.length == 0) {
            this.hasData = false;
          }
          this.totalPage = res.data.pageData.pageCount;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    /**
     * Chọn next trang tiếp theo trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    nextPage() {
      if (this.pageNumber != this.totalPage) {
        this.pageNumber += 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      this.reDisplay();
    },
    /**
     * Chọn prev trang trước đó trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    prevPage() {
      if (this.pageNumber != 1) {
        this.pageNumber -= 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      this.reDisplay();
    },
    async confirmUpdateCurrencySubmit() {

      const result = await this.swalWarning( { message:this.$NOLIMITResource[this.dataUser.language].Setting.WarningSavePriority, language: this.dataUser.language});
      // Kiểm tra xem người dùng đã xác nhận hay chưa
      if (result.isConfirmed) {
        this.updateCurrencySubmit()
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
  },
    updateCurrencySubmit(){
      if(this.validate()){
        this.$apiService.updateCurrencyConvert(this.currencyConvert)
        .then(()=>{
              this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessUpdatePriority)
        })
      }else{
        this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].Setting.ErrorValidateNumber, language: this.dataUser.language})
      }
    },  

    async confirmUpdateBinanceKeySubmit(){
      const result = await this.swalWarning( { message:this.$NOLIMITResource[this.dataUser.language].Setting.WarningSaveBinance, language: this.dataUser.language});
      // Kiểm tra xem người dùng đã xác nhận hay chưa
      if (result.isConfirmed) {
        this.updateBinanceKeySubmit()
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },
    updateBinanceKeySubmit(){
      this.$apiService.updateSettingBinanceById(1,this.settingBinance)
        .then(()=>{
              this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessUpdateBinance)
        })
    },

    selectAccount(s,id){
      
      this.$apiService.updateAccountStatusSetting(s,id)
      .then(()=>{
        if(s.status == true){
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessChooseBankActive)
        }else{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessStopBank)
        }  
        this.reDisplay()
      })
    },
    
    updateBankPrioritize(bankPrioritize,id){
      this.$apiService.updateBankPrioritizeSetting(bankPrioritize,id)
      .then(()=>{
        
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessChooseBankPriority)
         
        this.reDisplay()
      })
    },

    async deleteAccountConfirm(id,status){
      const result = await this.swalWarning({ message:this.$NOLIMITResource[this.dataUser.language].Setting.WarningConfirmDeleteBank, language: this.dataUser.language});
      // Kiểm tra xem người dùng đã xác nhận hay chưa
      if (result.isConfirmed) {
        this.deleteAccount(id,status)
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },

    deleteAccount(id,status){
        if(status == true){
            this.swalError({ message:this.$NOLIMITResource[this.dataUser.language].Setting.ErrorCantDeleteUsingBank, language: this.dataUser.language})
        }else{
            this.$apiService.deleteAccountSetting(id)
            .then(()=>{
                this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessDeleteBank)
                this.reDisplay()
            })
        }
        
    },
    openAddAccount(){
        this.onShowAddAccount = true;
    },
    closeModalAddAccount(){
        this.onShowAddAccount = false;
    },
    addAccount(){
      this.$apiService.addAccountSetting(this.accountAdd)
        .then(()=>{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].Setting.SuccessAddBank)
          this.reDisplay()
          this.onShowAddAccount = false;
      })
    }
  },
};
</script>
    