<template>
  <!--begin::Header-->
  <div data-v-0f1060f2="" class="modal fade show" @click="onCloseSidebarMobile" :class="{'block':this.getOnShowSidebarMobile == true}" id="kt_modal_add_customer" tabindex="-1" aria-hidden="true"></div>
  <div
    id="kt_app_header"
    data-kt-sticky-enabled="true"
    class="app-header"
    :class="{ onScroll: isScrolled }"
    data-kt-sticky="true"
    data-kt-sticky-activate="{default: true, lg: true}"
    data-kt-sticky-name="app-header-minimize"
    data-kt-sticky-offset="{default: '200px', lg: '0'}"
    data-kt-sticky-animation="false"
  >
    <!--begin::Header container-->
    <div
      class="app-container container-fluid d-flex align-items-stretch justify-content-between"
      id="kt_app_header_container"
    >
      <!--begin::Sidebar mobile toggle-->
      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
        title="Show sidebar menu"
      >
        <div
          class="btn btn-icon btn-active-color-primary w-35px h-35px"
          id="kt_app_sidebar_mobile_toggle"
        >
          <i class="ki-duotone ki-abstract-14 fs-2 fs-md-1" @click="onOpenSidebarMobile">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </div>
      </div>
      <!--end::Sidebar mobile toggle-->
      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a class="d-lg-none">
          <img
            alt="Logo"
            src="./../../../public/frontend/media/logos/logo3.png"
            class="h-30px"
          />
        </a>
      </div>
      <!--end::Mobile logo-->
      <!--begin::Header wrapper-->
      <div
        class="d-flex align-items-stretch justify-content-end flex-lg-grow-1"
        id="kt_app_header_wrapper"
      >
        <!--begin::Menu wrapper-->

        
        <!--begin::Notification-->
        <div
          class="app-navbar-item ms-1 ms-md-4 d-flex align-items-center"
          style="position: relative"
          @mouseover="showMenuNotification"
          @mouseout="hideMenuNotification"
        >
          <!--begin::Menu- wrapper-->
          <div
            class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            id="kt_menu_item_wow"
          >
            <i class="ki-duotone ki-notification-status fs-2">
              <span class="path1"></span>
              <span class="path2"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </i>
          </div>
          <!--begin::Menu-->
          <div
            class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px menu-notification"
            @mouseover="showMenuNotification"
            @mouseout="hideMenuNotification"
            :class="{ show: isHoverMenu }"
            data-kt-menu="true"
            id="kt_menu_notifications"
            style=""
          >
            <!--begin::Heading-->
            <div
              class="d-flex flex-column bgi-no-repeat rounded-top background-notification"
            >
              <!--begin::Title-->
              <h3 class="text-white fw-semibold px-9 mt-10 mb-6 text-start">
                {{ this.$NOLIMITResource[this.dataUser.language].Notification }}
              </h3>
              <!--end::Title-->
            </div>
            <!--end::Heading-->
            <!--begin::Tab content-->
            <div class="tab-content">
              <!--begin::Tab panel-->
              <div
                class="tab-pane fade active show"
                id="kt_topbar_notifications_1"
                role="tabpanel"
              >
                <!--begin::Items-->
                <div class="scroll-y mh-325px my-5 px-8">
                  <!--begin::Item-->
                  <div
                    class="d-flex flex-stack py-4"
                    v-for="(notification, index) in listNotification"
                    :key="index"
                  >
                    <!--begin::Section-->
                    <div class="d-flex align-items-center">
                      <!--begin::Symbol-->
                      <div class="symbol symbol-35px me-4">
                        <span class="symbol-label bg-light-primary">
                          <i
                            class="ki-duotone ki-abstract-28 fs-2 text-primary"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </span>
                      </div>
                      <!--end::Symbol-->
                        <!--begin::Title-->
                        <div v-if="!notification.message.includes('Yêu cầu thu hồi tài khoản ads')" class="mb-0 me-2" style="text-align: start">
                        <a
                          @click="updateReading(notification.notificationId)"
                          class="fs-6 text-gray-700 text-hover-primary fw-bold"
                          :class="{
                            read: notification.readingStatus == 'Đã đọc',
                          }"
                          >{{this.dataUser.language === 'VN' || notification.messageEn == null ? notification.message : notification.messageEn }}</a
                        >
                      </div>
                      <!--end::Title-->
                      <!--begin::Title-->
                      <div v-else class="mb-0 me-2" style="text-align: start">
                        <router-link
                          :to="{
                            name: 'ListAds',
                            query: {
                              idAds: this.getIdAds(notification.message),
                            },
                          }"
                          @click="updateReading(notification.notificationId)"
                          class="fs-6 text-gray-700 text-hover-primary fw-bold"
                          :class="{
                            read: notification.readingStatus == 'Đã đọc',
                          }"
                          >{{this.dataUser.language === 'VN' || notification.messageEn == null ? notification.message : notification.messageEn }}</router-link
                        >
                      </div>
                      <!--end::Title-->
                    </div>
                    <!--end::Section-->
                    <!--begin::Label-->
                    <span
                      class="badge badge-light fs-8"
                      :class="{ read: notification.readingStatus == 'Đã đọc' }"
                      >{{
                        this.formatDateRemain(notification.createDate)
                      }}</span
                    >
                    <!--end::Label-->
                  </div>
                  <!--end::Item-->
                </div>
                <!--end::Items-->
                <!--begin::View more-->
                <div class="py-3 text-center border-top">
                  <router-link
                    to="/listNotification"
                    class="btn btn-color-gray-600 btn-active-color-primary"
                    > {{ this.$NOLIMITResource[this.dataUser.language].SeeAll }}
                    <i class="ki-duotone ki-arrow-right fs-5">
                      <span class="path1"></span>
                      <span class="path2"></span> </i
                  ></router-link>
                </div>
                <!--end::View more-->
              </div>
              <!--end::Tab panel-->
            </div>
            <!--end::Tab content-->
          </div>
          <!--end::Menu-->
          <!--end::Menu wrapper-->
        </div>
        <!--end::Notification-->
        <!--begin::Navbar-->
        <div class="app-navbar flex-shrink-0">
          <!--begin::User menu-->
          <div
            class="app-navbar-item ms-1 ms-md-4 hover-lay"
            id="kt_header_user_menu_toggle"
          >
            <!--begin::Menu wrapper-->
            <div
              class="cursor-pointer symbol symbol-35px"
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
            >
              <div
                style="
                  width: 35px;
                  height: 35px;
                  background-color: #fff8dd;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <h1 style="color: #f6c000; font-size: 15px">
                  {{ this.firstUsername }}
                </h1>
              </div>
            </div>
            <!--begin::User account menu-->
            <div
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px menu-show"
              data-kt-menu="true"
            >
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <div class="menu-content d-flex align-items-center px-3">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-50px me-5">
                    <div
                      style="
                        width: 50px;
                        height: 50px;
                        background-color: #fff8dd;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <h1 style="color: #f6c000; font-size: 20px">
                        {{ this.firstUsername }}
                      </h1>
                    </div>
                  </div>
                  <!--end::Avatar-->
                  <!--begin::Username-->
                  <div class="d-flex flex-column">
                    <div class="fw-bold d-flex align-items-center fs-5">
                      {{ userCurrent.name }}
                      <span
                        v-if="userCurrent.isAdmin == true"
                        class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2"
                        >Admin</span
                      >
                    </div>
                    <a class="fw-semibold text-muted text-hover-primary fs-7"
                      >{{ userCurrent.email }}
                    </a>
                  </div>
                  <!--end::Username-->
                </div>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu separator-->
              <div class="separator my-2"></div>
              <!--end::Menu separator-->
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <router-link to="/" class="menu-link px-5"
                  >{{ this.$NOLIMITResource[this.dataUser.language].PersonalProfile }}</router-link
                >
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-5 my-1">
                <router-link to="/setting" class="menu-link px-5"
                  >{{ this.$NOLIMITResource[this.dataUser.language].SettingHeader }}</router-link
                >
              </div>
              <!--end::Menu item-->
              <div class="menu-item px-5 language-item" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                <a href="#" class="menu-link px-5">
                  <span class="menu-title position-relative">{{ this.$NOLIMITResource[this.dataUser.language].Language }} 
                  <span v-if="this.dataUserUpdate.language === 'EN'">
                    <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">{{ this.$NOLIMITResource[this.dataUser.language].EngLish }} 
                    <img class="w-15px h-15px rounded-1 ms-2" src="./../../../public/frontend/media/flags/united-states.svg" alt=""></span>
                  </span>
                  <span v-if="this.dataUserUpdate.language === 'VN' || this.dataUserUpdate.language === null || this.dataUserUpdate.language === ''">
                    <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">{{ this.$NOLIMITResource[this.dataUser.language].VietNamese }} 
                    <img class="w-15px h-15px rounded-1 ms-2" src="./../../../public/frontend/media/flags/vietnam.svg" alt=""></span>
                  </span>
                  </span>
                </a>
                <!-- begin::Menu sub-->
                <div class="menu-sub menu-sub-dropdown w-175px py-4 language-item-show" style="z-index: 108; position: fixed; inset:-47px 10px auto auto; margin: 0px; transform: translate(-275px, 305px);" data-popper-placement="left-start">
                  <!--begin::Menu item-->
                  <div class="menu-item px-3" @click="onChangeLanguage('EN')">
                    <a class="menu-link d-flex px-5" :class="{'active': this.dataUserUpdate.language === 'EU'}">
                    <span class="symbol symbol-20px me-4">
                      <img class="rounded-1" src="./../../../public/frontend/media/flags/united-states.svg" alt="">
                    </span>{{ this.$NOLIMITResource[this.dataUser.language].EngLish }}</a>
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-3" @click="onChangeLanguage('VN')">
                    <a class="menu-link d-flex px-5" :class="{'active': this.dataUserUpdate.language === 'VN'}">
                    <span class="symbol symbol-20px me-4">
                      <img class="rounded-1" src="./../../../public/frontend/media/flags/vietnam.svg" alt="">
                    </span> {{ this.$NOLIMITResource[this.dataUser.language].VietNamese }}</a>
                  </div>
                  <!--end::Menu item-->
                </div>
                <!--end::Menu sub -->
              </div>
              <!--begin::Menu item-->
              <div class="menu-item px-5">
                <router-link to="/SignIn" @click="logOut" te
                  >{{ this.$NOLIMITResource[this.dataUser.language].Logout }}</router-link
                >
              </div>
              <!--end::Menu item-->
            </div>
            <!--end::User account menu-->
            <!--end::Menu wrapper-->
          </div>
          <!--end::User menu-->

          <!--begin::Header menu toggle-->
          <div
            class="app-navbar-item d-lg-none ms-2 me-n2"
            title="Show header menu"
          >
            <div
              class="btn btn-flex btn-icon btn-active-color-primary w-30px h-30px"
              id="kt_app_header_menu_toggle"
            >
              <i class="ki-duotone ki-element-4 fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </div>
          </div>
          <!--end::Header menu toggle-->
          <!--begin::Aside toggle-->
          <!--end::Header menu toggle-->
        </div>
        <!--end::Navbar-->
      </div>
      <!--end::Header wrapper-->
    </div>
    <!--end::Header container-->
  </div>
  <!--end::Header-->

  <div
    class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
    data-kt-menu="true"
    id="kt_menu_notifications"
    style="
      z-index: 107;
      position: fixed;
      inset: 0px 0px auto auto;
      margin: 0px;
      transform: translate(-222px, -349px);
    "
    data-popper-placement="bottom-end"
  >
    <div
      class="d-flex flex-column bgi-no-repeat rounded-top"
      style="background-image: url('assets/media/misc/menu-header-bg.jpg')"
    >
      begin::Title
      <h3 class="text-white fw-semibold px-9 mt-10 mb-6">
        Thông báo <span class="fs-8 opacity-75 ps-3">24 reports</span>
      </h3>
      <!--end::Title-->
      <!--begin::Tabs-->
      <ul
        class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
            data-bs-toggle="tab"
            href="#kt_topbar_notifications_1"
            aria-selected="true"
            role="tab"
            >Alerts</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-white opacity-75 opacity-state-100 pb-4"
            data-bs-toggle="tab"
            href="#kt_topbar_notifications_2"
            aria-selected="false"
            role="tab"
            tabindex="-1"
            >Updates</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link text-white opacity-75 opacity-state-100 pb-4"
            data-bs-toggle="tab"
            href="#kt_topbar_notifications_3"
            aria-selected="false"
            tabindex="-1"
            role="tab"
            >Logs</a
          >
        </li>
      </ul>
      <!--end::Tabs-->
    </div>
    <!--end::Heading-->
  </div>
  <div class="toast" :class="{'show':openNotificationCreate == true}" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
        <i class="ki-duotone ki-abstract-39 fs-2 text-primary me-3"><span class="path1"></span><span class="path2"></span></i>
        <strong class="me-auto">Thông báo</strong>
        <small>Vừa xong</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body text-start" >
        {{ this.message }}
    </div>
  </div>
 
</template>
<style scope>
.block{
  margin-left: 250px ;
}
.menu-show {
  display: none;
  position: absolute;
  top: 70px;
  right: 30px;
}
.toast{
  z-index: 100;
  position: fixed;
    top: 0px;
    left: 30%;
}
.flex-shrink-0:hover .menu-show {
  display: block;
  transition: display 12s ease;
}
.menu-show:hover {
  display: flex;
}
.language-item:hover .language-item-show{
  display: block !important;
  transition: display 12s ease;
}
.onScroll {
  top: 0px;
  z-index: 100;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  background-color: #fff;
}
.background-notification {
  background-image: url("./../../../public/frontend/media/misc/menu-header-bg.jpg");
}
.menu-notification {
  position: absolute;
  top: 55px;
  right: 0px;
}
.read {
  color: #c3c3c2 !important;
}
.drawer-on{
  transition: transform 0.3s ease-in-out !important;
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
}
</style>
<script>
import router from "@/router/router.js";
import SignalRService from '@/signalr/signalrService.js';
import {mapMutations,mapGetters} from "vuex";
export default {
  data() {
    return {
      dataUserUpdate: {
        language: "VN",
      },
      dataUser: {
        language: "VN",
      },
      message:'',
      socket: null,
      userCurrent: {},
      firstUsername: "",
      isScrolled: false,
      isHoverMenu: false,
      listNotification: [],
      openNotificationCreate: false,
    };
  },

  created() {
    this.$apiService.getUserProfile().then((res) => {
      this.userCurrent = res.data.data;
      // this.dataUserUpdate = { ...res.data.data };
      if(res.data.data.language == "VN" || res.data.data.language == "EN"){
                    this.dataUserUpdate = res.data.data;
                    this.dataUser =res.data.data;
                }else{
                    this.dataUserUpdate = res.data.data;
                    this.dataUserUpdate.language = "VN";
                    this.dataUser =res.data.data;
                    this.dataUser.language = "VN";
                }
      this.getFirstUsername();
      this.$apiService
        .getAllNotification(res.data.data.userId)
        .then((response) => {
          this.listNotification = response.data.data;
        });
    }).catch(()=>{
      console.log("")
    });
  },
  computed:{
      ...mapGetters("listModule", [
      "getOnShowSidebarMobile",

    ]),
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    SignalRService.startConnection();
        SignalRService.onReceiveMessage((user, message) => {
          if(this.userCurrent.userId == user){
            this.message = message;
            this.openNotification()
          }
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    ...mapMutations("listModule", ["onOpenSidebarMobile","onCloseSidebarMobile"]),
    showMenuNotification() {
      this.isHoverMenu = true;
    },
    hideMenuNotification() {
      this.isHoverMenu = false;
    },
    logOut() {
      window.localStorage.removeItem("id-token");
      router.push({ name: "SignIn" });
    },
    getFirstUsername() {
      if (this.userCurrent.username.length > 0) {
        this.firstUsername = this.userCurrent.username.charAt(0).toUpperCase();
      } else {
        this.firstUsername = "";
      }
    },
    handleScroll() {
      // Kiểm tra vị trí cuộn của trang
      this.isScrolled = window.scrollY > 0;
    },
    formatDateRemain(createDate) {
      let currentDate = new Date();
      const createdDateTime = new Date(createDate);
      const timeDifference = currentDate - createdDateTime;
      const oneMinuteInMillis = 60 * 1000;
      const oneHourInMillis = 60 * oneMinuteInMillis;
      const oneDayInMillis = 24 * oneHourInMillis;

      if (timeDifference < oneMinuteInMillis) {
        // Nếu thời gian tạo nhỏ hơn 1 phút
        const seconds = Math.floor(timeDifference / 1000);
        return `${seconds} `+this.$NOLIMITResource[this.dataUser.language].SecondsAgo;
      } else if (timeDifference < oneHourInMillis) {
        // Nếu thời gian tạo nhỏ hơn 1 giờ
        const minutes = Math.floor(timeDifference / oneMinuteInMillis);
        return `${minutes} `+this.$NOLIMITResource[this.dataUser.language].MinutesAgo;
      } else if (timeDifference < oneDayInMillis) {
        // Nếu thời gian tạo nhỏ hơn 1 ngày
        const hours = Math.floor(timeDifference / oneHourInMillis);
        return `${hours} `+this.$NOLIMITResource[this.dataUser.language].MinutesAgo;
      } else if (timeDifference < 2 * oneDayInMillis) {
        // Nếu thời gian tạo là hơn 1 ngày trước
        return this.$NOLIMITResource[this.dataUser.language].OneDayAgo;
      } else {
        // Nếu thời gian tạo lớn hơn 1 ngày
        const days = Math.floor(timeDifference / oneDayInMillis);
        return `${days} `+this.$NOLIMITResource[this.dataUser.language].DaysAgo;
      }
    },
    updateReading(id) {
      let notification = {
        readingStatus: "Đã đọc",
        notificationId: id,
      };
      this.$apiService.updateNotificationById(notification).then(() => {
        this.reDisplay();
      }).catch(()=>{
        console.log("")
      });
    },
    reDisplay() {
      this.$apiService.getUserProfile().then((res) => {
        this.userCurrent = res.data.data;
        this.getFirstUsername();
        this.$apiService
          .getAllNotification(res.data.data.userId)
          .then((response) => {
            this.listNotification = response.data.data;
          });
      }).catch(()=>{
        console.log("")
      });
    },
    getIdAds(text) {
      // Use a regular expression to match ads IDF
      const match = text.match(/ads (\d+)/);

      // Check if there is a match
      if (match !== null) {
        // Extract ads ID from the match
        const adsId = match[1];
        return adsId;
      } else {
        console.error("No match found for ads ID in the given text.");
        return null;
      }
    },
    openNotification(){
      this.openNotificationCreate = true;
      setTimeout(()=>this.openNotificationCreate = false,8000);
    },
    onChangeLanguage(value){
        
        this.dataUserUpdate.language = value
        this.$apiService.updateUserProfile(this.dataUserUpdate).then(()=>{
          location.reload();
        })

    }
  },
};
</script>