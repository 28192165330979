


const NOLIMITResource = {
    VN:{
        BankFeeCard:"Đối soát phí thẻ",
        SecondsAgo:"giây trước",
        MinutesAgo:"phút trước",
        HoursAgo:"giờ trước",
        DaysAgo:"ngày trước",
        OneDayAgo:"1 ngày trước",
        EngLish:"Tiếng anh",
        VietNamese:"Tiếng việt",
        Language:"Ngôn ngữ",
        Logout:"Đăng xuất",
        SettingHeader:"Cài đặt",
        PersonalProfile:"Hồ sơ cá nhân",
        SeeAll:"Xem tất cả",
        Notification:"Thông báo",
        PermissionPage:"Bạn không có quyền vào trang này",
        Detail:"Chi tiết",
        Reset:"Đặt lại",
        Filter:"Lọc",
        StartDate:"Ngày bắt đầu",
        EndDate:"Ngày kết thúc",
        Save:"Lưu lại",
        Cancel:"Hủy bỏ",
        Add:"Thêm",
        AddAll:"Thêm toàn bộ",
        Feature:"Chức năng",
        SeeDetail:"Xem chi tiết",
        Account:"Tài khoản",
        Selected:"Đã chọn ",
        TopupFeePercent:"Phí topup (%)",
        Balance:"Tiền còn lại",
        TotalMoney:"Số tiền đã nạp",
        MoneyOut:"Tiền ra",
        MoneyIn:"Tiền vào",
        Email:"Email",
        FullName:"Họ tên",
        CardNumber: "Số thẻ",
        AccountOwner: "Tên chủ thẻ",
        BankName:"Tên ngân hàng",
        Currency:"Đơn vị tiền tệ",
        Total: "Tổng",
        DownloadCSV: "Tải về CSV",
        FilterSearch: "Lọc tìm kiếm",
        FilterOptions: "Tùy chọn bộ lọc",
        EmptyData:"Chưa có giao dịch nào",
        Failed:"Thất bại",
        Success:"Thành công",
        Processing:"Đang xử lý",
        NotDetermined:"Chưa xác định",
        NameAds:"Tên tài khoản ads",
        UserName:"Tên đăng nhập",
        Note:"Ghi chú",
        Amount:"Số tiền",
        AmountUSD:"Số tiền (USD)",
        TopupFee:"Phí topup",
        Status:"Trạng thái",
        PaymentAmount:"Tổng tiền thanh toán",
        LimitBeforeTopup:"Giới hạn trước topup",
        LimitAfterTopup:"Giới hạn sau topup",
        CreatedDate:"Ngày tạo",
        CardManager:{
            Title:"Quản lý thẻ",
            TextSearch: "Tìm kiếm theo thẻ,tên thẻ,tiền tệ,bank",
            DateFilter: "Lọc ngày",
        },
        SidebarCategory:{
            Home: "Trang chủ",
            AccountantManager: "Quản lý user kế toán",
            AdvertisingAccount: "Tài khoản quảng cáo",
            TopupList: "Danh sách các Topup",
            WalletHistory: "Lịch sử biến động ví",
            RefundHistory: "Lịch sử hoàn tiền",
            CreateRechargeInvoice: "Tạo hóa đơn nạp tiền",   
            RechargeInvoiceHistory: "Lịch sử hóa đơn nạp tiền",
            UserList: "Danh sách người dùng",
            AdvertisingAccountListAll: "Danh sách tài khoản ads",
            TopupListManager: "Danh sách tất cả Topup",
            AllRechargeInvoice: "Tất cả hóa đơn nạp tiền",
            AllAdsHistory: "Tất cả lịch sử ads",
            AllWalletHistory: "Tất cả lịch sử ví",
            AllRefundHistory: "Tất cả lịch sử hoàn tiền",
            BillLogManager: "Quản lý BillLog",
            CardManager:"Quản lý thẻ",
            Setting: "Cài đặt chung",
            AdsSpendHistory:"Lịch sử chi tiêu ads",
            AllRequestAds:"Tất cả yêu cầu cấp tài khoản",
            RechargeInvoiceBinanceHistory: "Lịch sử hoá đơn nạp tiền Binance",
            TopupThai:"Lịch sử chuyển tiền thái",
        },
        SibarCategoryMenu:{
            Account: "Tài khoản",
            AdvertisingAccountManager: "Quản lý tài khoản ads",
            Recharge: "Nạp tiền",
            AdministrativeFeatures:"Tính năng quản trị"
        },
        ProfileSetting:{
            Title: "Thông tin cá nhân",
            Admin: "Quản trị",
            User:"Người dùng",
            AmountInWallet:"Số tiền trong ví",
            AmountInWalletUSD:"Số tiền trong ví theo USD",
            TransferOtherUser:"Chuyển tiền cho người dùng khác",
            TransferOtherUserThai:"Chuyển tiền cho người dùng Thái",
            UsernameReceiveMoney:"Username nhận tiền",
            TitleUsernameReceiveMoney:"Username của tài khoản nhận tiền",
            TitleAmountTransfer:"Số tiền muốn chuyển (VND)",
            TitleAmountTransferUSD:"Số tiền muốn chuyển (USD)",
            TitleFacebookAdsId:"FacebookAdsId",
            TransferAmount:"Chuyển tiền",
            Info:"Thông tin",
            Commission:"Tiền hoa hồng",
            TotalCommisionTitle:"Tổng số tiền hoa hồng bạn kiếm được",
            TotalCommission:"Tổng số tiền",
            PaidCommission:"Đã rút",
            RemainCommission:"Còn lại",
            InfoDetail:"Thông tin chi tiết",
            SuccessTransfer:"Cập nhật thông tin thành công!",
            SuccessUpdateInfo:"Chuyển tiền thành công!",
        },
        DashBoard:{
            Today: "Hôm nay",
            Card_One: "Số tiền nạp vào ví (số dư/tổng nạp)",
            Card_Two: "Số tiền đã topup",
            Card_Three: "Số tiền nhận vào / chuyển đi",
            Card_Four: "Số tiền cộng / trừ bởi admin",
            Overview: "Tổng quan",
            Recharge: "Nạp ví",
            Topup: "Topup",
            One_Year:"1 năm",
            One_Week:"1 tuần",
            One_Day:"1 ngày",
            Months:[
                "Tháng 1",
                "Tháng 2",
                "Tháng 3",
                "Tháng 4",
                "Tháng 5",
                "Tháng 6",
                "Tháng 7",
                "Tháng 8",
                "Tháng 9",
                "Tháng 10",
                "Tháng 11",
                "Tháng 12",
              ],
            DaysOfWeek:[
                "Chủ Nhật",
                "Thứ Hai",
                "Thứ Ba",
                "Thứ Tư",
                "Thứ Năm",
                "Thứ Sáu",
                "Thứ Bảy",
              ], 
            UserWallet: "Người dùng - ví", 
            SeeAll: "Xem thêm",
            TopupHistory: "Lịch sử topup", 
            AdvertisingAccountStatistics:"Thống kê tài khoản quảng cáo",
            AdAccountMetrics:"Chỉ số của tài khoản quảng cáo bạn đã sử dụng",
            NumberOfDeadAccounts:"Số tài khoản chết",
            NumberOfAccountsGrantedToday:"Số tài khoản cấp trong ngày",
            NumberOfAccountsWithdrawn:"Số tài khoản thu hồi",
            NumberOfActiveAccounts:"Số tài khoản hoạt động",
            NumberOfAccountsUsed:"Tất cả tài khoản đã sử dụng",
        },

        ListUserByAccountant:{
            Title:"Quản lý user của kế toán",
            SearhText:"Tìm kiếm theo username",
        },
        AdsAccount:{
            TotalAmountTopUp:"Tổng số tiền đã topup",
            DailyAmountTopUp:"Số tiền nạp trong ngày",
            TotalInvoiceAmount:"Sô tiền đã lập hóa đơn",
            InvoiceAmountOnDay:"Số tiền tạo hóa đơn trong ngày",
            TotalSpend:"Tổng số tiền đã chi tiêu",
            SpendingNow:"Số tiền đã chi tiêu",
            SpendOnDay:"Số tiền chi tiêu trong ngày",
            IdBusinessPartner:"ID doanh nghiệp đối tác",
            NameBusinessOwner:"Tên doanh nghiệp sở hữu",
            IdbusinessOwner:"ID doanh nghiệp sở hữu",
            RemainPaymentDate:"Ngày thanh toán còn",
            InvoiceDate:"Ngày lập hóa đơn",
            RemainThreshold:"Ngưỡng thanh toán còn lại",
            PaymentThreshold:"Ngưỡng thanh toán",
            DailyLimit:"Giới hạn chi tiêu mỗi ngày",
            SpentLimit:"Giới hạn chi tiêu",
            RemoveDuplicateAccounts:"Loại bỏ tk trùng",
            AddMultiAdInput:"Thêm hàng loạt (FacebookAdId|Phí)",
            AddMultipleAdsAccounts:"Thêm hàng loạt tài khoản ads",
            UpdateBatchTopupFeePickedAds:"Sửa phí Topup các tài khoản đã chọn",
            GrantAccountsToUsers:"Cấp các tài khoản cho user",
            ProvideAds:"Cấp tài khoản",
            EditAdAccount:"Sửa tài khoản ads",
            Recall:"Thu hồi",
            ProvideDate:"Ngày cấp",
            RequestUpdate:"Yêu cầu cập nhật",
            RecallAd:"Thu hồi tài khoản",
            ProvideAdsToUser:"Cấp tài khoản cho user",
            AllTopup:"Tất cả topup",
            UpdateTopupFee:"Cập nhật phí topup",
            AddBatchAds:"Thêm hàng loạt",
            AddAd:"Thêm tài khoản ads",
            ProvideAdsForManyUser:"Cấp cho toàn bộ user",
            RecallAdsForManyUser:"Thu hồi toàn bộ user",
            UpdateBatchTopupFee:"Cập nhật hàng loạt phí Topup",
            Nodata:"Chưa có tài khoản ads",
            SearhTextAllAds:"Tìm kiếm theo tên, facebookAdsId",
            TitleAllAds:"Quản lý tất cả tài khoản quảng cáo",
            Title:"Tài khoản quảng cáo",
            SearhText:"Tìm kiếm theo tên, facebookAdsId, khách hàng",
            TopupAll:"Topup toàn bộ",
            CustomizeSelectedCustomers:"Tùy chỉnh toàn bộ khách hàng",
            RequestUpdateAll:"Yêu cầu cập nhật tất cả",
            TopupByFile:"Topup theo file",
            SystemStatus:"Trạng thái ở hệ thống",
            SpenCap:"Giới hạn chi tiêu còn lại",
            LastTopup:"Topup gần nhất",
            Customer:"Khách hàng",
            Supporter:"Người hỗ trợ",
            ReturnDate:"Ngày thu hồi",
            PaymentMethod:"Phương thức thanh toán",
            Timezone:"Múi giờ",
            Nation:"Quốc gia",
            PartnerBusinessID:"ID doanh nghiệp đối tác",
            DepositAmountToday:"Số tiền nạp trong ngày",
            TotalAmountTopup:"Tổng số tiền đã topup",
            LastUpdate:"Ngày cập nhật gần nhất",
            AmountFee:"Tiền phí",
            Ads:"Tài khoản quảng cáo",
            CreateTopup:"Tạo TopUp",
            TopupHistory:"Lịch sử TopUp",
            AdsHistory:"Lịch sử Ads",
            RequestReturn:"Yêu cầu thu hồi",
            CustomerCustomization:"Khách hàng tùy chỉnh",
            Refund:"Hoàn tiền",
            StatusPaymentRequired:"Cần thanh toán",
            StatusDisable:"Vô hiệu hóa",
            StatusUpdateting:"Đang cập nhật",
            StatusWork:"Hoạt động",
            StatusInUse:"Đang sử dụng",
            StatusRecalled:"Đã thu hồi",
            StatusWaitingForRecall:"Chờ thu hồi",
            TheAmountAddedToTheAds:"Số tiền thêm vào tài khoản ads",
            TopupFeeTitle:"Phí topup theo tài khoản quảng cáo",
            TotalAmountNeedPayment:"Tổng tiền cần thanh toán",
            TotalAmountPaymentTitle:"Tổng tiền sau khi thêm phí topup",
            AddManyTopupTitle:"Thêm toàn bộ TopUp Order",
            ChooseDisplayColumns:"Chọn các thông tin hiển thị",
            RequestReturnMessage:"Hãy tắt hết camp trước khi thu hồi! Bạn có chắc chắn muốn yêu cầu thu hồi tài khoản này ",
            ConfirmRefund:"Bạn chỉ có thể được hoàn tiền trong trường hợp tài khoản die! Bạn có chắc chắn muốn hoàn lại tiền ",
            ConfirmUpdateAllAds:"Bạn có chắc chắn muốn cập nhật tất cả tài khoản đã chọn ",
            ErrorAdsRevoked:"Tài khoản đã thu hồi. Bạn không thể topup!",
            ErrorAdsNoTopupFee:"Tài khoản chưa có phí topup.Liên hệ hỗ trợ để thêm phí topup cho tài khoản này!",
            ErrorAdsRevokedCantRevoke:"Tài khoản đã thu hồi không thể thu hồi!",
            ErrorAdsWaitingRevoke:"Tài khoản đang chờ thu hồi!",
            ErrorCantReadThisFile:"Không thể đọc file này!",
            ErrorBrowserReadFile:"Trình duyệt Không hỗ trợ đọc file này!",
            SuccessAddTopup:"Thêm TopUp order thành công!",
            SuccessAddBatchTopup:"Thêm các TopUp order thành công!",
            SuccessPickColumns:"Đã chọn các thông tin hiển thị!",
            SuccessCreateRequestRefund:"Tạo yêu cầu hoàn tiền thành công!",
            SuccessSendRequestRefund:"Gửi yêu cầu thu hồi thành công",
            SuccessCustomization:"Tùy chỉnh thành công!",
            SuccessCustomizationAds:"Tùy chỉnh các ads thành công!",
            SuccessRequestUpdateAd:"Yêu cầu cập nhật các tài khoản thành công!",
            ValidateEmptyNumber:"Vui lòng nhập số tiền muốn thêm",
            ValidateIsNotNumber:"Vui lòng nhập số",
            ValidateNumberTooLarge:"Số tiền nhập quá lớn! Vui lòng nhập lại!", 
            NotYetUsed:"Chưa sử dụng",
            NumberOfAccounts:"Số lượng tài khoản:",
            StatusAds:"Trạng thái tài khoản",
            SuccessGrantAccountsToUsers:"Cấp user cho các tài khoản thành công!",
            SuccessRecallAccounts:"Thu hồi các tài khoản thành công!",
            SuccessProvideAccount:"Cấp tài khoản thành công!",
            SuccessUpdateInfoAds:"Cập nhật thông tin tài khoản thành công!",
            SuccessRecallAllAds:"Thu hồi tất cả thành công!",
            SuccessRecallAd:"Thu hồi tài khoản thành công!",
            SuccessAddAd:"Thêm tài khoản ads thành công!",
            SuccessAdd:"Thêm thành công!",
            SuccessRequestUpdate:"Yêu cầu cập nhật thành công!",
            SuccessUpdateTopupFee:"Cập nhật phí topup tài khoản thành công!",
            SuccessUpdateTopupFeeAds:"Cập nhật phí topup các tài khoản đã chọn thành công!",
            ErrorAdRecalled:"Tài khoản đã bị thu hồi không thể cấp lại",
            WarningRecallAll:"Bạn có chắc chắn muốn thu hồi tất cả ",
            WarningUpdateAllPickAd:"Bạn có chắc chắn muốn cập nhật tất cả tài khoản đã chọn ",
            WarningUpdateThisAd:"Bạn có chắc chắn muốn thu hồi tài khoản này ",
            ErrorValidateUserIdEmpty:"Vui lòng điền userId",
            ErrorValidateUserId:"UserId không đúng định dạng",
            ErrorValidateFacebookAdsIdIdEmpty:"FacebookAdsId không được để trống",
            ErrorValidateFacebookAdsId:"Bạn phải nhập chữ số và không quá 16 ký tự",
            RequestAds:"Yêu cầu cấp tài khoản",
            ListRequestAds:"Danh sách yêu cầu",
            SubmitRequestAds:"Gửi yêu cầu cấp tài khoản ads",
            Quantity:"Số lượng",
            SubmitButtonRequestAds:"Gửi yêu cầu",
            ContentCustomerCustomization:"Nội dung",
        },
        TopUpAccount:{
            Title:"Danh sách các TopUp",
            NumberOfTopups:"Số lượng topup",
            SearhText:"Tìm kiếm theo FBAdsId, Ghi chú",
            SearhTextAccountant:"Tìm kiếm theo FBAdsId, Ghi chú, Username",
            AddTopup:"Thêm topup",
            AddBatchTopup:"Thêm hàng loạt topup",
            Nodata:"Chưa có dữ liệu TopUp",
            UpdateTopup:"Sửa TopUp",
            DeleteTopup:"Xóa TopUp",
            TitleNoteTopup:"Ghi chú của topup",
            AmountTopup:"Số tiền topup",
            TitleCreatedTopup:"Ngày tạo topup",
            TitleStatusTopup:"Trạng thái topup",
            SuccessUpdateTopup:"Cập nhật thành công topup!",
            SuccessAddManyTopup:"Thêm thành công các topup!",
            SuccessDeleteTopup:"Xóa thành công topup ",
            WarningDeleteTopup:"Bạn có chắc chắn muốn xóa topup này ",
            SuccessAddTopup:"Thêm TopUp thành công!",

        },
        BalanceHistory:{
            Title:"Lịch sử biến động ví",
            TextSearch:"Tìm kiếm theo thông tin giao dịch",
            StatusRecharge:"Nạp tiền",
            StatusRechargeBinance:"Nạp tiền Binance",
            StatusAdminPlusAmount:"Admin cộng tiền",
            StatusAdminMinusAmount:"Admin trừ tiền",
            StatusTransferMoney:"Chuyển tiền",
            StatusTransferMoneyThai:"Chuyển tiền Thái",
            StatusAdminTransferMoney:"Admin chuyển tiền",
            StatusReceiveMoney:"Nhận tiền",
            StatusCreateTopup:"Tạo đơn Topup",
            StatusRefund:"Hoàn tiền",
            StatusUnkown:"Chưa phân loại",
            TransactionDate:"Ngày giao dịch",
            TransactionType:"Loại giao dịch",
            AmountBeforeChange:"Số tiền trước thay đổi",
            AmountChange:"Số tiền thay đổi",
            AmountAfterChange:"Số tiền sau thay đổi",
            TransactionInformation:"Thông tin giao dịch",  
            TitleAll:'Lịch sử biến động tất cả các ví', 
            TextSearchAll:"Tìm kiếm theo userId,Username",
        },
        RefundHistory:{
            Title:"Lịch sử hoàn tiền",
            TextSearch:"Tìm kiếm theo FacebookAdsId",
            Status:"Trạng thái hoàn tiền",
            CreatedDate:"Ngày tạo",
            UpdateDate:"Ngày cập nhật",
            Spent:"Đã chi tiêu",
            SpentLimit:"Giới hạn chi tiêu",
            RemainingAmount:"Giới hạn chi tiêu còn lại",
            CurrentRefundAmount:"Số tiền hoàn lần đầu",
            ReconciliationRefundAmount:"Số tiền hoàn đợt 2",
            TotalRefundAmount:"Tổng tiền đã hoàn",
            TitleAll:"Lịch sử hoàn tiền toàn bộ User",
            TextSearchAll:"Tìm kiếm theo FacebookAdsId, UserId",
            RefundRound2:"Hoàn tiền đợt 2",
            AmountRefundRound2:"Số tiền hoàn đợt 2",
            SuccessUpdateRefundRound2:"Cập nhật hoàn tiền đợt 2 thành công!",
            ErrorUpdateRefundRound2:"Đã hoàn tiền đợt 2 không thể hoàn nữa!",
            StatusRefundComplete:"Hoàn thành",
            StatusRefund90:"Đã hoàn tiền 90%",
            StatusRefundFailed:"Hoàn tiền thất bại",
        },
        Recharge:{
            Warning:"Lưu ý",
            PleaseNote:"Vui lòng điền đúng nội dung chuyển tiền",
            PleaseNote2:"để thực hiện thanh toán tự động",
            Title:"Tạo hóa đơn nạp tiền",
            DepositAmount: "Số tiền nạp là",
            CreateInvoice:"Tạo hóa đơn",
            CreateInvoiceSuccess:"Tạo hóa đơn thành công!",
            AmountNotValidate:"Số tiền nhập không đúng định dạng",
            TitleBinance:"Tạo hoá đơn nạp tiền Binance bằng USDT",
        },
        ListDeposit:{
            Title:"Quản lý hóa đơn nạp tiền",
            TextSearch:"Tìm kiếm theo mã số hóa đơn",
            StatusPaid:"Đã thanh toán",
            StatusUnPaid:"Chưa thanh toán",
            StatusProcess:"Đang xử lý",
            NumberOfTransactions:"Số lượng giao dịch",
            DepositHistoryCode:"Mã số hóa đơn",
            DepositAmount:"Số tiền nạp",
            DepositCreateDate:"Thời gian tạo",
            DepositDate:"Thời gian thanh toán",
            DepositStatus:"Trạng thái",
            NameBank:"Ngân hàng thanh toán",
            OwnerBank:"Chủ tài khoản thanh toán",
            NumberBank:"Số tài khoản thanh toán",
            TitleAll:"Quản lý tất cả hóa đơn nạp tiền",
            TextSearchAll:"Tìm kiếm theo mã số, userId, username",
        },
        ListDepositBinance:{
            Title:"Quản lý hóa đơn nạp tiền Binance",
            TextSearch:"Tìm kiếm theo mã số hóa đơn",
            StatusPaid:"Đã thanh toán",
            StatusUnPaid:"Chưa thanh toán",
            StatusProcess:"Đang xử lý",
            NumberOfTransactions:"Số lượng giao dịch",
            DepositHistoryCode:"Mã số hóa đơn",
            DepositAmount:"Số tiền nạp",
            DepositCreateDate:"Thời gian tạo",
            DepositDate:"Thời gian thanh toán",
            DepositStatus:"Trạng thái",
            BinanceId:"ID Binance",
            TitleAll:"Quản lý tất cả hóa đơn nạp tiền Binance",
            TextSearchAll:"Tìm kiếm theo mã số, userId, username",
        },
        DetailDeposit:{
            StatusPaid:"Đã thanh toán",
            AwaitingPayment:"Đang chờ thanh toán",
            DetailedInvoice:"Hóa đơn chi tiết",
            Bank:"Ngân hàng",
            BankNumber:"Số tài khoản",
            BankOwner:"Chủ tài khoản",
            AmountToBePaid:"Số tiền cần thanh toán",
            DepositCode:"Mã hóa đơn",
            PaymentTerm:"Hạn thanh toán",
            PaymentTimeRemaining:"Thời gian thanh toán còn",
            SelectPaymentAccount:"Chọn tài khoản thanh toán",
            ScanQRCodeToPay:"Quét mã QR để thanh toán",
            Use:"Sử dụng",
            Qr:"hoặc ứng dụng camera hỗ trợ QR code để quét mã",
            PaymentSuccessful:"Thanh toán thành công!",
        },
        UserManagement:{
            Title:"Quản lý người dùng",
            Nodata:"Chưa có dữ liệu",
            TextSearch:"Tìm kiếm người dùng",
            StatusWork:"Hoạt động",
            StatusInactive:"Không hoạt động",
            UpdateInformation:"Sửa thông tin",
            ChangeTopupFee:"Đổi phí TopUp",
            AddMoney:"Cộng tiền",
            MinusMoney:"Trừ tiền",
            RefundMoney:"Hoàn tiền",
            LockAccount:"Khóa tài khoản",
            UnlockAccount:"Mở tài khoản",
            GrantPermissionAccountant:"Cấp quyền kế toán",
            RevokePermissionAccountant:"Thu quyền kế toán",
            GrantPermissionAffiliate:"Cấp quyền affiliate",
            RevokePermissionAffiliate:"Thu quyền affiliate",
            ProvideUserforAccountant:"Cấp user quản lý",
            UpdateUserInformation:"Sửa thông tin người dùng",
            TitleUsername:"Tên đăng nhập của người dùng",
            TitleFullname:"Họ tên của người dùng",
            TitleEmail:"Email của người dùng",
            PhoneNumber: "Số điện thoại",
            TitlePhone:"Số điện thoại của người dùng",
            TitleFacebook:"Facebook của người dùng",
            TitleTelegram:"Telegram của người dùng",
            TitleZalo:"Zalo của người dùng",
            BalanceNow:"Số tiền hiện tại",
            TitleBalanceNow:"Số tiền còn lại của người dùng",
            AmountDeposited:"Số tiền đã nạp",
            TitleAmountDeposited:"Số tiền đã nạp của người dùng",
            FacebookAdsIdRefund:"FacebookAdsId hoàn tiền",
            AmountWantToAdd:"Số tiền muốn cộng",
            AmountWantToRefund:"Số tiền muốn hoàn",
            TitleAmountWantToAdd:"Số tiền muốn cộng vào tài khoản của người dùng",
            TitleAmountWantToRefund:"Số tiền muốn hoàn vào tài khoản của người dùng",
            AmountWantToMinus:"Số tiền muốn trừ",
            TitleAmountWantToMinus:"Số tiền muốn trừ vào tài khoản của người dùng",
            ThePlusAmountIs:"Số tiền cộng là",
            TheTransferAmountIs:"Số tiền chuyển VND là",
            TheMinusAmountIs:"Số tiền trừ là",
            TheRefundAmountIs:"Số tiền hoàn là",
            GrantUserIdToManagementAccounting:"Cấp userId cho kế toán quản lý",
            GrantToAccounting:"Cấp cho kế toán",
            AssignPermissions:"Phân quyền",
            UserId:"Id Người dùng",
            User:"Người dùng",
            SuccessGrantToAccounting:"Cấp quyền kế toán thành công",
            SuccessRecallAccounting:"Thu quyền kế toán thành công",
            SuccessGrantToAffiliate:"Cấp quyền affiliate thành công",
            SuccessRecallAffiliate:"Thu quyền affiliate thành công",
            SuccessUserManagementPermissionGrantedSuccessfully:"Cấp quyển quản lý user thành công!",
            SuccessLockAccount:"Khóa tài khoản thành công!",
            SuccessUnlockAccount:"Mở tài khoản thành công!",
            SuccessPlusMoney:"Cộng tiền tài khoản thành công!",
            SuccessMinusMoney:"Trừ tiền tài khoản thành công!",
            SuccessRefundMoney:"Hoàn tiền tài khoản thành công!",
            SuccessUpdateUserInfo:"Cập nhật thông tin người dùng thành công!",
            ErrorNotEnoughMinus:"Tài khoản còn lại không đủ để trừ!",
            SuccessGrantToSupport:"Cấp quyền hỗ trợ thành công",
            SuccessRecallSupport:"Thu quyền hỗ trợ thành công",
            GrantPermissionSupport:"Cấp quyền hỗ trợ",
            RevokePermissionSupport:"Thu quyền hỗ trợ",
            AddCommissionAdAccount:"Thêm tài khoản hoa hồng",
            PercentCommission:"Phần trăm hoa hồng",
            ConfirmDeleteCommission:"Bạn có chắc muốn xoá hoa hồng này",
            RemainCommission:"Hoa hồng còn lại",
            WithdrawnCommission:"Hoa hồng đã rút",
            AddCommissionAdAccountMulti:"Thêm hàng loạt tài khoản hoa hồng",
        },
        Setting:{
            AddBank:"Thêm tài khoản",
            LoginAccount:"Tài khoản đăng nhập",
            LoginPassword:"Mật khẩu đăng nhập",
            NameBank:"Tên tài khoản",
            NumberBank:"Số tài khoản",
            Bank:"Tên ngân hàng",
            AddAccount:"Thêm tài khoản",
            SettingExchangeRate:"Cài đặt tỷ giá USD/VND",
            SettingAccountBank:"Cài đặt tài khoản thanh toán",
            Prioritize:"Ưu tiên",
            StatusBank:"Trạng thái hoạt động",
            StatusPrioritize:"Đang ưu tiên",
            StatusActive:"Đang hoạt động",
            StatusInactive:"Dừng hoạt động",
            TurnOnUse:"Bật Sử dụng",
            TurnOffUse:"Tắt sử dụng",
            MakeItPriority:"Đặt làm ưu tiên",
            Delete:"Xóa",
            ErrorValidateEmpryPriority:"Vui lòng nhập tỷ giá USD sang VND",
            ErrorValidateNumber:"Vui lòng nhập số",
            SuccessUpdatePriority:"Cập nhật tỷ giá USD thành công!",
            SuccessChooseBankActive:"Sử dụng tài khoản thành công!",
            SuccessStopBank:"Dừng sử dụng tài khoản thành công!",
            SuccessChooseBankPriority:"Chọn tài khoản ưu tiên thành công!",
            SuccessDeleteBank:"Xóa tài khoản thành công!",
            SuccessAddBank:"Thêm tài khoản thành công!",
            ErrorCantDeleteUsingBank:"Không thể khóa tài khoản đang sử dụng!",
            WarningSavePriority:"Bạn có chắc chắn muốn lưu tỷ giá này ",
            WarningConfirmDeleteBank:"Bạn có chắc chắn muốn xóa tài khoản này ",
            SuccessUpdateBinance:"Cập nhật Binance thành công!",
            WarningSaveBinance:"Bạn có chắc chắn muốn lưu cài đặt Binance này ",
        },
        AdsHistory:{
            Title:"Lịch sử tài khoản quảng cáo",
            TextSearch:"Tìm kiếm theo mã giao, mã cấp tài khoản, FbadsId",
            EmpryData:"Chưa có dữ liệu Ads",
            DeliveryCode:"Mã giao tài khoản",
            ReturnCode:"Mã trả tài khoản",
            DeliveryDate:"Ngày giao tài khoản",
            ReturnDate:"Ngày trả tài khoản",
            TitleAll:"Lịch sử tất cả tài khoản quảng cáo",
        },
        ListTopupOfAds:{
            Title:"Danh sách các TopUp của Ads",
            TextSearch:"Tìm kiếm theo mã FacebookAdsId, Ghi chú",
        },
        TopupDetail:{
            Title:"Lịch sử TopUp",
            Time:"Thời gian",
        },
        DetailCommission:{
            Title:"Bảng chi tiết tiền hoa hồng",
            SearchText:"Tìm kiếm theo FacebookAdsId",
            SpendingDifference:"Chi tiêu",
            CommissionEarned:"Số tiền kiếm được",
        },
        AdsSpendHistory:{
            Title:"Lịch sử chi tiêu ads",
            SearchText:"Tìm kiếm theo FacebookAdsId",
        },
        RequestAds:{
            StatusRequest:"Đang yêu cầu"
        },
        TopupThai:{
            Title:"Bảng lịch sử chuyển tiền thái",
            SearchText:"Tìm kiếm theo FacebookAdsId",
            FacebookAdsId:"FacebookAdsId",
            Name:"Name",
            CustomerID:"CustomerID",
            Amount:"Amount",
            AmountPayAble:"AmountPayAble",
            Fee:"Fee",
            BankFee:"BankFee",
            AmountBankFee:"AmountBankFee",
            ProfitFee:"ProfitFee",
            TotalProfit:"TotalProfit",
            PartProfit:"PartProfit",
            PaidForNolimit:"PaidForNolimit",
            CreatedDate:"CreatedDate"

        }
        
    },
    EN:{
        BankFeeCard:"Check fee card",
        SecondsAgo:"second ago",
        MinutesAgo:"minute Ago",
        HoursAgo:"hour ago",
        DaysAgo:"day ago",
        OneDayAgo:"one day ago",
        EngLish:"EngLish",
        VietNamese:"VietNamese",
        Language:"Language",
        Logout:"Log out",
        SettingHeader:"Setting",
        PersonalProfile:"Personal profile",
        SeeAll:"See all",
        Notification:"Notification",
        PermissionPage:"You do not have permission to access this page",
        Detail:"Detail",
        Reset:"Reset",
        Filter:"Filter",
        StartDate:"Start date",
        EndDate:"End date",
        Save:"Save",
        Cancel:"Cancel",
        Add:"Add",
        AddAll:"Add all",
        Feature:"Feature",
        SeeDetail:"See more",
        Account:"Account",
        Selected:"Selected ",
        TopupFeePercent:"Topup fee (%)",
        Balance:"Balance",
        TotalMoney:"Total amount recharge ",
        MoneyOut:"Money out",
        MoneyIn:"Money in",
        Email:"Email",
        FullName:"Fullname",
        CardNumber: "CardNumber",
        AccountOwner: "AccountOwner",
        BankName:"BankName",
        Currency:"Currency",
        Total: "Total",
        DownloadCSV: "DownloadCSV",
        FilterSearch: "FilterSearch",
        FilterOptions: "Filter Options",
        EmptyData: "No data available",
        Failed:"Failed",
        Success:"Success",
        Processing:"Processing", 
        NameAds:"Ads account name",
        UserName:"UserName",
        Note:"Note",
        Amount:"Amount",
        AmountUSD:"Amount (USD)",
        TopupFee:"TopupFee",
        Status:"Status",
        PaymentAmount:"PaymentAmount",
        LimitBeforeTopup:"Limit Before Topup",
        LimitAfterTopup:"Limit After Topup",
        CreatedDate:"Created Date",
        CardManager:{
            Title:"Card Manager",
            TextSearch: "Search by card, card name, currency, bank name",
            DateFilter: "DateFilter",
        },
        SidebarCategory:{
            Home: "Home Page",
            AccountantManager: "Accountant Manager",
            AdvertisingAccount: "Advertising Account", 
            TopupList: "Topup List",
            WalletHistory: "Wallet History",      
            RefundHistory: "Refund History",  
            CreateRechargeInvoice: "Create Recharge Invoice",
            RechargeInvoiceHistory: "Recharge Invoice History",
            UserList: "User List",
            AdvertisingAccountListAll: "Advertising Account Manager",
            TopupListManager: "Topup List Manager",
            AllRechargeInvoice: "Recharge Invoice List Manager",
            AllAdsHistory: "Ads History Manager",
            AllWalletHistory: "Wallet History Manager",
            AllRefundHistory: "Refund History Manager",
            BillLogManager: "BillLog Manager",
            CardManager:"Card Manager",
            Setting: "Setting",
            AdsSpendHistory:"Ads Spend History",
            AllRequestAds:"All Request Provide Ads",
            RechargeInvoiceBinanceHistory: "Recharge Invoice History Binance",
            TopupThai:"Transfer Money Thai Histories",
        },
        SibarCategoryMenu:{
            Account: "Account",
            AdvertisingAccountManager: "Advertising Account Manager",
            Recharge: "Recharge",
            AdministrativeFeatures:"Administrative Features",

        },
        ProfileSetting:{
            Title: "Personal information",
            Admin: "Admin",
            User:"User",
            AmountInWallet:"Amount in wallet",
            AmountInWalletUSD:"Amount in wallet in USD",
            TransferOtherUser:"Transfer money to another user",
            TransferOtherUserThai:"Transfer money to user Thai",
            UsernameReceiveMoney:"Username receives money",
            TitleUsernameReceiveMoney:"Username of the receiving account",
            TitleAmountTransfer:"Amount you want to transfer (VND)",
            TitleAmountTransferUSD:"Amount you want to transfer (USD)",
            TitleFacebookAdsId:"FacebookAdsId",
            TransferAmount:"Transfer amount",
            Info:"Infomation user",
            Commission:"Commissions",
            TotalCommisionTitle:"The total amount of commissions you earned",
            TotalCommission:"Total",
            PaidCommission:"Paid",
            RemainCommission:"Balance",
            InfoDetail:"Detailed information",
            SuccessTransfer:"Money transfer successful!",
            SuccessUpdateInfo:"Updated information user successfully!",
        },
        DashBoard:{
            Today: "Today",
            Card_One: "Amount deposited into the wallet (balance/total deposit)",
            Card_Two: "Amount of Topup ads",
            Card_Three: "Money in/Money out",
            Card_Four: "Amount added/subtracted by admin",
            Overview: "Overview",
            Recharge: "Recharge",
            Topup: "Topup",
            One_Year:"One year",
            One_Week:"One week",
            One_Day:"One day",
            Months:
            [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            DaysOfWeek: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
            ],
            UserWallet: "User - wallet", 
            SeeAll: "See all",  
            TopupHistory: "Topup history",
            AdvertisingAccountStatistics:"Advertising Account Statistics",
            AdAccountMetrics:"Metrics of the ad account",
            NumberOfDeadAccounts:"Number Of Dead Accounts",
            NumberOfAccountsGrantedToday:"Number Of Accounts Granted Today",
            NumberOfAccountsWithdrawn:"Number Of Accounts Withdrawn",
            NumberOfActiveAccounts:"Number Of Active Accounts",
            NumberOfAccountsUsed:"Total Number Of Accounts Used",
        },
     
        ListUserByAccountant:{
            Title:"Manage accounting users",
            SearhText:"Search by username",
        },
        AdsAccount:{
            TotalAmountTopUp:"Total amount topup",
            DailyAmountTopUp:"Daily amount topup",
            TotalInvoiceAmount:"Total invoice amount",
            InvoiceAmountOnDay:"Invoice amount on day",
            TotalSpend:"Total spend",
            SpendingNow:"Spending now",
            SpendOnDay:"Spend on day",
            IdBusinessPartner:"Id business partner",
            NameBusinessOwner:"Name business owner",
            IdbusinessOwner:"Idbusiness owner",
            RemainPaymentDate:"Remain payment date",
            InvoiceDate:"Invoice date",
            RemainThreshold:"Remain threshold",
            PaymentThreshold:"Payment threshold",
            DailyLimit:"Daily limit",
            SpentLimit:"Spent limit",
            RemoveDuplicateAccounts:"Remove duplicate accounts",
            AddMultiAdInput:"Add multiple ads (FacebookAdId|TopupFee)",
            AddMultipleAdsAccounts:"Add multiple ads accounts",
            UpdateBatchTopupFeePickedAds:"Edit Topup fees for selected accounts",
            GrantAccountsToUsers:"Provide many ads to user",
            ProvideAds:"Provide Ad",
            EditAdAccount:"Edit ad account",
            Recall:"Recall",
            ProvideDate:"Provide date",
            RequestUpdate:"Request update ad",
            RecallAd:"Recall ad",
            ProvideAdsToUser:"Provide ad to user",
            AllTopup:"All topup",
            UpdateTopupFee:"Update topup fee",
            AddBatchAds:"Add batch ads account",
            AddAd:"Add an ads account",
            ProvideAdsForManyUser:"Provide ads to many user",
            RecallAdsForManyUser:"Recall ads to many user",
            UpdateBatchTopupFee:"Update batch topup fee",
            Nodata:"Empty data ads",
            SearhTextAllAds:"Search by name,facebookAdId",
            TitleAllAds:"Manage all advertising accounts",
            Title:"Advertising accounts",
            SearhText:"Search by name,facebookAdId,customer",
            TopupAll:"Topup all selected",
            CustomizeSelectedCustomers:"Customize selected customers",          
            RequestUpdateAll:"Request update all",
            TopupByFile:"Topup by file",
            SystemStatus:"System status",
            SpenCap:"Spendingcap",
            LastTopup:"Last topup date",
            Customer:"Customer",
            Supporter:"Supporter",
            ReturnDate:"Revocation date",
            PaymentMethod:"Payment method",
            Timezone:"Timezone",
            Nation:"Nation",
            PartnerBusinessID:"Partner business ID",
            DepositAmountToday:"Deposit amount today",
            TotalAmountTopup:"Total amount topup",
            LastUpdate:"Last update",
            AmountFee:"Amount Fee",
            Ads:"Account advertising",
            CreateTopup:"Create topup",
            TopupHistory:"Topup history",
            AdsHistory:"Ads history",
            RequestReturn:"Request return",
            CustomerCustomization:"Customer customization",
            Refund:"Refund",
            StatusPaymentRequired:"Payment required",
            StatusDisable:"Disable",
            StatusUpdateting:"Updateting",
            StatusWork:"Work",
            StatusInUse:"In use",
            StatusRecalled:"Recalled",
            StatusWaitingForRecall:"Waiting for recall",
            TheAmountAddedToTheAds:"The amount added to the ads account",
            TopupFeeTitle:"Topup fee according to advertising account",
            TotalAmountNeedPayment:"Total amount to be paid",
            TotalAmountPaymentTitle:"Total amount after adding topup fee",
            AddManyTopupTitle:"Add entire TopUp Order",
            ChooseDisplayColumns:"Choose the display columns",
            RequestReturnMessage:"Please turn off all camps before recalling! Are you sure you want to request this account to be revoked?",
            ConfirmRefund:"You can only get a refund in account cases! Are you sure you want a refund?",
            ConfirmUpdateAllAds:"Are you sure you want to update all selected accounts?",
            ErrorAdsRevoked:"Account has been revoked. You can't top up!",
            ErrorAdsNoTopupFee:"The account does not have a topup fee. Contact support to add a topup fee to this account!",
            ErrorAdsRevokedCantRevoke:"Revoked accounts cannot be revoked!",
            ErrorAdsWaitingRevoke:"Account pending Revoke!",
            ErrorCantReadThisFile:"Cannot read this file!",
            ErrorBrowserReadFile:"Browser does not support reading this file!",
            SuccessAddTopup:"Create TopUp order successfully!",
            SuccessAddBatchTopup:"Create successful TopUp orders!",
            SuccessPickColumns:"Display columns selected!",
            SuccessCreateRequestRefund:"Created a successful refund request!",
            SuccessSendRequestRefund:"Submitted a recall request successfully!",
            SuccessCustomization:"Customization successful",
            SuccessCustomizationAds:"Customization successful ads",
            SuccessRequestUpdateAd:"Request to update ad successfully!",
            ValidateEmptyNumber:"Please enter the amount you want to add",
            ValidateIsNotNumber:"Please enter number",
            ValidateNumberTooLarge:"The amount entered is too large! Please re-enter!",
            NotDetermined:"Not determined",
            NotYetUsed:"Not yet used",
            NumberOfAccounts:"Number of accounts:",
            StatusAds:"Status Ads",
            SuccessGrantAccountsToUsers:"Provide accounts to users successfully!",
            SuccessRecallAccounts:"Recall accounts successfully!",
            SuccessProvideAccount:"Provide account successfully!",
            SuccessUpdateInfoAds:"Updated account information successfully!",
            SuccessRecallAllAds:"Recall all successfully!",
            SuccessRecallAd:"Recall account successfully!",
            SuccessAddAd:"Added ads account successfully!",
            SuccessAdd:"Added successfully!",
            SuccessRequestUpdate:"Update request successful!",
            SuccessUpdateTopupFee:"Update topup fee successful!",
            SuccessUpdateTopupFeeAds:"Update topup fee many ads successful!",
            ErrorAdRecalled:"Accounts that have been revoked cannot be reissued",
            WarningRecallAll:"Are you sure you want to revoked them all?",
            WarningUpdateAllPickAd:"Are you sure you want to update all selected accounts?",
            WarningUpdateThisAd:"Are you sure you want to revoke this account?",
            ErrorValidateUserIdEmpty:"Please fill in userId",
            ErrorValidateUserId:"UserId is not in correct format",
            ErrorValidateFacebookAdsIdIdEmpty:"FacebookAdsId cannot be blank",
            ErrorValidateFacebookAdsId:"You must enter numbers and no more than 16 characters",
            RequestAds:"Request Provide Ads",
            ListRequestAds:"List Request",
            SubmitRequestAds:"Submit a request for advertising accounts",
            Quantity:"Quantity",
            SubmitButtonRequestAds:"Send Request",
            ContentCustomerCustomization:"Note",
        },
        TopUpAccount:{
            Title:"List of TopUps",
            NumberOfTopups:"Number of topups",
            SearhText:"Search by FBAdsId, Notes",
            SearhTextAccountant:"Search by FBAdsId, Notes, Username",
            AddTopup:"Add Topup",
            AddBatchTopup:"Add batch topup",
            Nodata:"Empty topup",
            UpdateTopup:"Update topup",
            DeleteTopup:"Delete topup",
            TitleNoteTopup:"Topup notes",
            AmountTopup:"Amount topup",
            TitleCreatedTopup:"Created date topup",
            TitleStatusTopup:"Status topup",
            SuccessUpdateTopup:"Successfully updated topup!",
            SuccessAddManyTopup:"Successfully added topups!",
            SuccessDeleteTopup:"Successfully deleted topup ",
            WarningDeleteTopup:"Are you sure you want to delete this topup?",
            SuccessAddTopup:"Successfully added topup!",
        },
        BalanceHistory:{
            Title:"Wallet History",
            TextSearch:"Search by transaction information",
            StatusRecharge:"Recharge",
            StatusRechargeBinance:"Recharge Binance",
            StatusAdminPlusAmount:"Admin plus amount",
            StatusAdminMinusAmount:"Admin minus amount",
            StatusTransferMoney:"Transfer amount",
            StatusTransferMoneyThai:"Transfer amount Thai",
            StatusAdminTransferMoney:"Admin transfer amount",
            StatusReceiveMoney:"Receive amount",
            StatusCreateTopup:"Create topup",
            StatusRefund:"Refund",
            StatusUnkown:"Unkown",
            TransactionDate:"Transaction date",
            TransactionType:"Transaction type",
            AmountBeforeChange:"Amount before change",
            AmountChange:"Amount change",
            AmountAfterChange:"Amount after change",
            TransactionInformation:"Transaction information",
            TitleAll:'History of all wallets',    
            TextSearchAll:"Search by userId, Username",
        },
        RefundHistory:{
            Title:"Refund History",
            TextSearch:"Search by FacebookAdsId",
            Status:"Refund status",
            CreatedDate:"Created Date",
            UpdateDate:"Update Date",
            Spent:"Spent",
            SpentLimit:"Spent Limit",
            RemainingAmount:"Remaining Amount",
            CurrentRefundAmount:"Current Refund Amount",
            ReconciliationRefundAmount:"Reconciliation Refund Amount",
            TotalRefundAmount:"Total Refund Amount",
            TitleAll:"Refund history for all Users",
            TextSearchAll:"Search by FacebookAdsId, UserId",
            RefundRound2:"Refund round 2",
            AmountRefundRound2:"Refund amount for round 2",
            SuccessUpdateRefundRound2:"Refund update for round 2 successfully!",
            ErrorUpdateRefundRound2:"The second round of refunds have been completed and cannot be refunded anymore!",
            StatusRefundComplete:"Complete",
            StatusRefund90:"90% refunded",
            StatusRefundFailed:"Refund failed",

        },
        Recharge:{
            Warning:"Warning",
            PleaseNote:"Please enter the exact transfer details note",
            PleaseNote2:"to make automatic payments",
            Title: "Create recharge invoice",
            DepositAmount: "Deposit amount",
            CreateInvoice: "Create invoice",
            CreateInvoiceSuccess:"Create invoice success!",
            AmountNotValidate:"The amount entered is not in the correct format",
            TitleBinance:"Create a Binance deposit invoice using USDT",
        },
        ListDeposit:{
            Title:"Manage recharge invoices",
            TextSearch:"Search by code invoice",
            StatusPaid:"Paid",
            StatusUnPaid:"Unpaid",
            StatusProcess:"Processing",
            NumberOfTransactions:"Number of transactions",
            DepositHistoryCode:"DepositCode",
            DepositAmount:"Deposit Amount",
            DepositCreateDate:"Create Date",
            DepositDate:"Payment Date",
            DepositStatus:"Status",
            NameBank:"Name Bank Payment",
            OwnerBank:"Owner Bank Payment",
            NumberBank:"Number Bank Payment",
            TitleAll:"Manage all recharge invoices",
            TextSearchAll:"Search by code invoice, userId, username",
        },
        ListDepositBinance:{
            Title:"Manage recharge invoices Binance",
            TextSearch:"Search by code invoice",
            StatusPaid:"Paid",
            StatusUnPaid:"Unpaid",
            StatusProcess:"Processing",
            NumberOfTransactions:"Number of transactions",
            DepositHistoryCode:"DepositCode",
            DepositAmount:"Deposit Amount",
            DepositCreateDate:"Create Date",
            DepositDate:"Payment Date",
            DepositStatus:"Status",
            BinanceId:"ID Binance",
            TitleAll:"Manage all recharge invoices Binance",
            TextSearchAll:"Search by code invoice, userId, username",
        },
        DetailDeposit:{
            StatusPaid:"Paid",
            AwaitingPayment:"Awaiting payment",
            DetailedInvoice:"Detailed invoice",
            Bank:"Bank",
            BankNumber:"Bank Number",
            BankOwner:"Bank Owner",
            AmountToBePaid:"Amount payable",
            DepositCode:"Deposit code",
            PaymentTerm:"Payment term",
            PaymentTimeRemaining:"Payment time remaining",
            SelectPaymentAccount:"Select payment account",
            ScanQRCodeToPay:"Scan QR code to pay",
            Use:"Use",
            Qr:"or camera app that supports QR code to scan the code",
            PaymentSuccessful:"Payment successful!",
        },
        UserManagement:{
            
            Title:"User management",
            Nodata:"No data yet",
            TextSearch:"Search user",
            StatusWork:"Active",
            StatusInactive:"Inactive",
            UpdateInformation:"Update information",
            ChangeTopupFee:"Change TopupFee",
            AddMoney:"Add amount",
            MinusMoney:"Minus amount",
            RefundMoney:"Refund amount",
            LockAccount:"Lock account",
            UnlockAccount:"UnlockAccount",
            GrantPermissionAccountant:"Grant permission accountant",
            RevokePermissionAccountant:"Revoke permission accountant",
            GrantPermissionAffiliate:"Grant permission  affiliate",
            RevokePermissionAffiliate:"Revoke permission affiliate",
            ProvideUserforAccountant:"Provide a userId for accountant management",
            UpdateUserInformation:"Update user information",
            TitleUsername:"User login name",
            TitleFullname:"User's full name",
            TitleEmail:"User's email",
            PhoneNumber: "Phone",
            TitlePhone:"User's phone number",
            TitleFacebook:"User's Facebook",
            TitleTelegram:"User's Telegram",
            TitleZalo:"User's Zalo",
            BalanceNow:"Balance Now",
            TitleBalanceNow:"The remaining amount of the user",
            AmountDeposited:"Amount deposited",
            TitleAmountDeposited:"User's deposited amount",
            FacebookAdsIdRefund:"FacebookAdsId refund",
            AmountWantToAdd:"The amount you want to plus",
            AmountWantToRefund:"The amount you want to refund",
            TitleAmountWantToAdd:"The amount you want to plus to the user's account",
            TitleAmountWantToRefund:"The amount you want to refund to the user's account",
            AmountWantToMinus:"The amount you want to minus",
            TitleAmountWantToMinus:"The amount you want to minus to the user's account",
            ThePlusAmountIs:"The plus amount is",
            TheTransferAmountIs:"The transfer amount is",
            TheMinusAmountIs:"The minus amount is",
            TheRefundAmountIs:"The refund amount is",
            GrantUserIdToManagementAccounting:"Grant userId to management accounting",
            GrantToAccounting:"Grant to accounting",
            AssignPermissions:"Assign Permissions",
            UserId:"UserId",
            User:"User",
            SuccessGrantToAccounting:"Accounting permission granted successfully",
            SuccessRecallAccounting:"Accounting permission recalled successfully",
            SuccessGrantToAffiliate:"Affiliate permission granted successfully",
            SuccessRecallAffiliate:"Affiliate permission recalled successfully",
            SuccessUserManagementPermissionGrantedSuccessfully:"User management permission granted successfully!",
            SuccessLockAccount:"Account locked successfully!",
            SuccessUnlockAccount:"Account unlocked successfully!",
            SuccessPlusMoney:"Account added money successfully!",
            SuccessMinusMoney:"Account minus money successfully!",
            SuccessRefundMoney:"Account refund money successfully!",
            SuccessUpdateUserInfo:"Updated user information successfully!",
            ErrorNotEnoughMinus:"The remaining account is not enough to minus!",
            SuccessGrantToSupport:"Support permission granted successfully",
            SuccessRecallSupport:"Support permission recalled successfully",
            GrantPermissionSupport:"Grant permission support",
            RevokePermissionSupport:"Revoke permission support",
            AddCommissionAdAccount:"Add Commission Ad Account",
            PercentCommission:"Commission(%)",
            ConfirmDeleteCommission:"Are you sure you want to delete this commission?",
            RemainCommission:"Remaining Commission",
            WithdrawnCommission:"Paid Commission",
            AddCommissionAdAccountMulti:"Add Batch Commission Ad Account",
        },
        Setting:{
            AddBank:"Add bank",
            LoginAccount:"Login account",
            LoginPassword:"Login password",
            NameBank:"Bank name",
            NumberBank:"Bank number",
            Bank:"Bank",
            AddAccount:"Add account",
            SettingExchangeRate:"Set USD/VND exchange rate",
            SettingAccountBank:"Payment account settings",
            Prioritize:"Prioritize",
            StatusBank:"Operating status",
            StatusPrioritize:"Prioritizing",
            StatusActive:"Active",
            StatusInactive:"Inactive",
            TurnOnUse:"Turn on use",
            TurnOffUse:"Turn off use",
            MakeItPriority:"Make it a priority",
            Delete:"Delete",
            ErrorValidateEmpryPriority:"Please enter USD to VND exchange rate",
            ErrorValidateNumber:"Please enter number",
            SuccessUpdatePriority:"Successfully updated USD exchange rate!",
            SuccessChooseBankActive:"Use account successfully!",
            SuccessStopBank:"Stop using account successfully!",
            SuccessChooseBankPriority:"Select priority account successfully!",
            SuccessDeleteBank:"Account deleted successfully!",
            SuccessAddBank:"Account added successfully!",
            ErrorCantDeleteUsingBank:"Unable to lock the account in use!",
            WarningSavePriority:"Are you sure you want to save this rate?",
            WarningConfirmDeleteBank:"Are you sure you want to delete this bank?",
            SuccessUpdateBinance:"Successfully updated Binance!",
            WarningSaveBinance:"Are you sure you want to save this setting Binance? ",
        },
        AdsHistory:{
            Title:"Ad account history",
            TextSearch:"Search by DeliveryCode, ReturnCode, FbadsId",
            EmpryData:"No data Ads",
            DeliveryCode:"DeliveryCode",
            ReturnCode:"ReturnCode",
            DeliveryDate:"DeliveryDate",
            ReturnDate:"ReturnDate",
            TitleAll:"All ad account history",
        },
        ListTopupOfAds:{
            Title:"List of TopUps by AdsId",
            TextSearch:"Search by FacebookAdsId, Note",
        },
        TopupDetail:{
            Title:"TopUp History",
            Time:"Time",
        },
        DetailCommission:{
            Title:"Commission details ",
            SearchText:"Search by FacebookAdsId",
            SpendingDifference:"Spending",
            CommissionEarned:"Commission Earned",
        },
        AdsSpendHistory:{
            Title:"Ads Spend History ",
            SearchText:"Search by FacebookAdsId",
        },
        RequestAds:{
            StatusRequest:"Requesting"
        },
        TopupThai:{
            Title:"Transfer Money Thai History",
            SearchText:"Search by FacebookAdsId",
            FacebookAdsId:"FacebookAdsId",
            Name:"Name",
            CustomerID:"CustomerID",
            Amount:"Amount",
            AmountPayAble:"AmountPayAble",
            Fee:"Fee",
            BankFee:"BankFee",
            AmountBankFee:"AmountBankFee",
            ProfitFee:"ProfitFee",
            TotalProfit:"TotalProfit",
            PartProfit:"PartProfit",
            PaidForNolimit:"PaidForNolimit",
            CreatedDate:"CreatedDate"
        }
    }
}

export default NOLIMITResource;