import * as signalR from '@microsoft/signalr';
import axios from 'axios';
const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl("https://api.nolimitadsmanager.com/chatHub",{ withCredentials: true })
    // .withUrl("https://localhost:7001/chatHub",{ withCredentials: true })
    .configureLogging(signalR.LogLevel.None)  // Tắt tất cả log
    .build();

// Kiểm tra quyền Notification API
function requestNotificationPermission() {
    if (Notification.permission === "default") {
        Notification.requestPermission().then(permission => {
            if (permission === "granted") {
                console.log("Notification permission granted.");
            } else {
                console.warn("Notification permission denied.");
            }
        });
    }
}
    // Hiển thị thông báo desktop
function showDesktopNotification(title, message) {
    if (Notification.permission === "granted") {
        const notification = new Notification(title, {
            body: message,
            icon: "https://nolimitadsmanager.com/img/logo3.57415797.png" // Thay bằng đường dẫn icon của bạn
        });

        // Xử lý sự kiện khi người dùng nhấp vào thông báo
        notification.onclick = () => {
            window.focus(); // Đưa tab hiện tại lên
        };
    } else {
        console.warn("Notifications are not permitted.");
    }
}
export default {
    startConnection() {
        hubConnection.start()
            .then(()=>{
                requestNotificationPermission(); 
            })
            .catch(err => console.error('Error while starting connection: ', err));
    },
    onReceiveMessage(callback) {
        hubConnection.on('ReceiveMessage', (user, message) => {
            callback(user, message);
            let token = window.localStorage.getItem('id-token');
            axios.get('https://api.nolimitadsmanager.com/api/User/UserDetails', { headers: { Authorization: token }
            }).then(response => {
               if(response.data.data.isAdmin == true && response.data.data.userId == user){
                showDesktopNotification(`Tin nhắn từ user`, message);
               }
            })
            
        });
    },
    sendMessage(user, message) {
        hubConnection.invoke('SendMessage', user, message)
            .catch(err => console.error(err));
    }
};
