<template>
    <div class="detail-deposit">
        <div class="col-xl-4" style="margin-left: 40px; margin-right: 20px">
            <!--begin::List Widget 1-->
            <div class="card card-xl-stretch mb-xl-8">
                <div class="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten"
                    style="    min-height: 430px;">
                    <!--begin::Labels-->
                    <div class="mb-8 flex">
                        <span v-if="this.depositHistory.status == 'Đã thanh toán'"
                            class="badge badge-light-success me-2"> {{
                                this.$NOLIMITResource[this.dataUser.language].DetailDeposit.StatusPaid }}</span>
                        <span v-else class="badge badge-light-warning">{{
                            this.$NOLIMITResource[this.dataUser.language].DetailDeposit.AwaitingPayment }}</span>
                    </div>
                    <!--end::Labels-->
                    <!--begin::Title-->
                    <h6 class="mb-8 fw-bolder text-gray-600 text-hover-primary flex">
                        {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.DetailedInvoice }}
                    </h6>
                    <!--end::Title-->
                    <!--begin::Item-->
                    <div class="mb-6 flex">
                        <div class="fw-semibold text-gray-600 fs-7">Binance ID:</div>

                        <div class="fw-bold text-gray-800 fs-6">{{ this.depositHistory.binanceId }}</div>
                    </div>
                    <!--end::Item-->

                    <!--begin::Item-->
                    <div class="mb-6 flex">
                        <div class="fw-semibold text-gray-600 fs-7">
                            {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.AmountToBePaid }}:
                        </div>
                        <div class="fw-bold text-gray-800 fs-6">
                            {{ this.depositHistory.amount }} USDT
                        </div>
                    </div>

                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="mb-6 flex">
                        <div class="fw-semibold text-gray-600 fs-7">
                            {{ this.$NOLIMITResource[this.dataUser.language].DetailDeposit.DepositCode }}:
                        </div>
                        <div class="fw-bold text-gray-800 fs-6">{{ this.depositHistory.transactionCode }} <button
                                style="display: inline;" ref="copyBtn" class="copy-btn" @click="copyCode"
                                :class="{ active: isCopying }">
                                <i class="fa fa-copy"></i> <!-- Icon copy -->
                            </button></div>

                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div class="mb-6 flex" v-if="this.depositHistory.transactionId != null">
                        <div class="fw-semibold text-gray-600 fs-7">
                            TransactionID from Binance:
                        </div>
                        <div class="fw-bold text-gray-800 fs-6">{{ this.depositHistory.transactionId }}</div>
                    </div>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <div v-if="this.depositHistory.status == 'Chưa thanh toán'" class="mb-0 flex">
                        <div class="fw-semibold text-gray-600 fs-7">{{
                            this.$NOLIMITResource[this.dataUser.language].DetailDeposit.PaymentTerm }}:</div>
                        <div class="fw-bold fs-6 text-gray-800 d-flex align-items-center">

                            <span class="fs-7 text-danger d-flex align-items-center">
                                <span class="bullet bullet-dot bg-danger mx-2"></span>{{
                                    this.$NOLIMITResource[this.dataUser.language].DetailDeposit.PaymentTimeRemaining }}
                                {{ this.remainingTime }}</span>
                        </div>
                    </div>
                    <!--end::Item-->
                    <div v-if="copySuccess" class="copy-success">
                        Copied to clipboard!
                    </div>
                </div>
            </div>
            <!--end::List Widget 1-->
        </div>

        <div class="card col-xl-7" style="height: 430px">

            <!--begin::Card body-->
            <div class="card-body">

                <!--begin::Heading-->
                <div class="card-px text-center pt-15 pb-15">
                    <!--begin::Title-->
                    <h2 class="fs-2x fw-bold mb-0">{{
                        this.$NOLIMITResource[this.dataUser.language].DetailDeposit.ScanQRCodeToPay }}</h2>
                    <!--end::Title-->
                    <!--begin::Item-->
                    <div class="mb-6 flex" style="flex-direction: row;justify-content: center;">
                      <span class="text-danger fw-bold mr">{{ this.$NOLIMITResource[this.dataUser.language].Recharge.Warning }}: </span>
                        <span class="fw-semibold text-danger fs-7 mr">
                          {{ this.$NOLIMITResource[this.dataUser.language].Recharge.PleaseNote }}
                        </span>
                        <span class="fw-bold text-gray-800 fs-6 mr">{{ this.depositHistory.transactionCode }} <button
                                style="display: inline;" ref="copyBtn2" class="copy-btn" @click="copyCode2"
                                :class="{ active: isCopying2 }">
                                <i class="fa fa-copy"></i> <!-- Icon copy -->
                            </button></span>
                        <span class="fw-semibold text-danger fs-7 mr">
                          {{ this.$NOLIMITResource[this.dataUser.language].Recharge.PleaseNote2 }}
                        </span>
                    </div>
                    <div v-if="copySuccess2" class="copy-success">
                        Copied to clipboard!
                    </div>
                    <!--end::Item-->
                    <img class="img-qr" src="./../../../../public/frontend/media/logos/QrBinance.png" width="100%" />
                </div>
                <!--end::Heading-->

            </div>
            <!--end::Card body-->
        </div>

    </div>

</template>
<style scoped>
.copy-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.mr{
  margin-right: 5px;
}
.copy-btn {
    cursor: pointer;
    color: #fff;
    border: none;
    width: 20px;
    border-radius: 4px;
}

.copy-btn.active {
    background-color: #28a745;
    /* Màu xanh khi copy thành công */
}

.copy-success {
    margin-top: 10px;
    color: green;
}

.d-flex {}

.img-qr {
    width: 300px;
}

.py-7 {
    padding: 0px !important;
    font-size: 13px !important;
}

.card-px {
    padding-top: 10px !important;
}

.flex {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.block-choose-bank button {
    margin-right: 10px;
}

.block-choose-bank {
    margin-top: 20px;
}

.block-choose-bank h2 {
    color: #34bbc3;
}

@media only screen and (max-width: 5000px) {
    .detail-deposit {
        display: flex;
    }
}

@media only screen and (max-width: 600px) {
    .detail-deposit {
        display: block;
    }
}
</style>
<script>
import { mapActions } from "vuex";
import Clipboard from "clipboard";
export default {

    name: "DetailDepositBinance",
    data() {
        return {
            isCopying: false,
            copySuccess: false,
            isCopying2: false,
            copySuccess2: false,
            dataUser: { language: "VN" },
            depositHistory: {},
            remainingTime: "",
            accountSetting: [],
            accountCurrent: {

            },
            clipboard1: null,
            clipboard2: null,
        };
    },
    computed: {

    },
    props: ["idDeposit"],
    async created() {
        await this.$apiService
            .getBinanceDepositHistoryById(this.idDeposit)
            .then((res) => {
                this.depositHistory = res.data.data;
                // Gọi hàm cập nhật thời gian mỗi giây
                this.updateRemainingTime();
                setInterval(this.updateRemainingTime, 1000); // Cập nhật mỗi giây
            })
            .catch((error) => {
                console.error("Lỗi khi lấy dữ liệu:", error);
            });
        this.$apiService
            .getAllSettingAccountNoPaging()
            .then((res) => {
                this.accountSetting = res.data.data;
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].status == true && res.data.data[i].bankPrioritize) {
                        this.accountCurrent = { ...res.data.data[i] };
                        break;
                    }
                }

            })
            .catch((error) => {
                console.error("Lỗi khi lấy dữ liệu:", error);
            });

        this.$apiService.getUserProfile().then((response) => {
            if (response.data.data.language == "VN" || response.data.data.language == "EN") {
                this.dataUser = response.data.data;
            } else {
                this.dataUser = response.data.data;
                this.dataUser.language = "VN";
            }
        })


        this.interval = setInterval(this.callApis, 2000);


    },
    mounted() {
        // Khởi tạo Clipboard cho nút đầu tiên
        this.clipboard1 = new Clipboard(this.$refs.copyBtn, {
            text: () => this.depositHistory.transactionCode,
        });
        this.clipboard1.on("success", () => {
            this.isCopying = true;
            this.copySuccess = true;
            setTimeout(() => {
                this.isCopying = false;
                this.copySuccess = false;
            }, 1000);
        });

        this.clipboard1.on("error", () => {
            console.error("Failed to copy!");
        });

        // Khởi tạo Clipboard cho nút thứ hai
        this.clipboard2 = new Clipboard(this.$refs.copyBtn2, {
            text: () => this.depositHistory.transactionCode,
        });
        this.clipboard2.on("success", () => {
            this.isCopying2 = true;
            this.copySuccess2 = true;
            setTimeout(() => {
                this.isCopying2 = false;
                this.copySuccess2 = false;
            }, 1000);
        });

        this.clipboard2.on("error", () => {
            console.error("Failed to copy!");
        });
    },
   
    methods: {
        ...mapActions("listModule", ["swalSuccess", "swalError", "swalWarning"]),
        callApis() {
            if (this.depositHistory.status == 'Chưa thanh toán') {
                this.$apiService
                    .getBinanceDepositHistoryById(this.idDeposit)
                    .then((res) => {
                        this.depositHistory = res.data.data;
                        if (this.depositHistory.status == "Đã thanh toán") {
                            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].DetailDeposit.PaymentSuccessful)
                        }
                        // Gọi hàm cập nhật thời gian mỗi giây
                        this.updateRemainingTime();
                        setInterval(this.updateRemainingTime, 1000); // Cập nhật mỗi giây
                    })
                    .catch((error) => {
                        console.error("Lỗi khi lấy dữ liệu:", error);
                    });
            }
        },
        copyCode() {
            const clipboard = new Clipboard(this.$refs.copyBtn, {
                text: () => this.depositHistory.transactionCode, // Nội dung cần copy
            });

            clipboard.on("success", () => {
                this.isCopying = true;
                this.copySuccess = true; // Hiển thị thông báo thành công
                setTimeout(() => {
                    this.isCopying = false;

                }, 100);
                setTimeout(() => {

                    this.copySuccess = false; // Ẩn thông báo sau 2 giây
                }, 2000);
                clipboard.destroy(); // Hủy clipboard sau khi copy
            });

            clipboard.on("error", () => {
                this.isCopying = false;
                console.error("Failed to copy!");
                clipboard.destroy(); // Hủy clipboard nếu lỗi
            });
        },
        copyCode2() {
            const clipboard = new Clipboard(this.$refs.copyBtn2, {
                text: () => this.depositHistory.transactionCode, // Nội dung cần copy
            });

            clipboard.on("success", () => {
                this.isCopying2 = true;
                this.copySuccess2 = true; // Hiển thị thông báo thành công
                setTimeout(() => {
                    this.isCopying2 = false;

                }, 100);
                setTimeout(() => {

                    this.copySuccess2 = false; // Ẩn thông báo sau 2 giây
                }, 2000);
                clipboard.destroy(); // Hủy clipboard sau khi copy
            });

            clipboard.on("error", () => {
                this.isCopying2 = false;
                clipboard.destroy(); // Hủy clipboard nếu lỗi
            });
        },
        tinhThoiGianThanhToan(thoiGianTaoHoaDon) {
            const thoiGianTaoHoaDonObj = new Date(thoiGianTaoHoaDon);
            const thoiGianThanhToanObj = new Date(thoiGianTaoHoaDonObj.getTime() + 24 * 60 * 60 * 1000);
            const thoiGianHienTaiObj = new Date();
            const khoangCachThoiGian = thoiGianThanhToanObj - thoiGianHienTaiObj;

            // Chia khoảng cách thời gian cho 1000 để đổi sang giây
            const giay = Math.floor(khoangCachThoiGian / 1000);

            // Tính số giờ, phút và giây
            const gio = Math.floor(giay / 3600);
            const phut = Math.floor((giay % 3600) / 60);
            const giayConLai = giay % 60;

            // Định dạng kết quả
            const thoiGianThanhToanFormatted = `${gio.toString().padStart(2, '0')}:${phut.toString().padStart(2, '0')}:${giayConLai.toString().padStart(2, '0')}`;

            return thoiGianThanhToanFormatted;
        },
        updateRemainingTime() {
            this.remainingTime = this.tinhThoiGianThanhToan(this.depositHistory.createdDate);
        },
        formatCurrency(number) {
            const formattedCurrency = number.toLocaleString('vi-VN', {
                style: 'currency',
                currency: 'VND'
            });
            return formattedCurrency;
        },
        chooseBank(bank, name, numberBank) {
            this.accountCurrent.bank = bank;
            this.accountCurrent.name = name;
            this.accountCurrent.value = numberBank;
        }
    },
};
</script>