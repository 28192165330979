<template>
    <!--begin::Sidebar-->
    <div id="kt_app_sidebar" class="app-sidebar flex-column " :class="{'drawer drawer-start drawer-on': this.getOnShowSidebarMobile == true}" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
                    <!--begin::Logo-->
                    <div class="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
                        <!--begin::Logo image-->
                        <a>
                            <img v-if="isOpenMenu" alt="Logo" src="./../../../public/frontend/media/logos/logo2.png" class="h-25px app-sidebar-logo-default" />
                            <img v-else alt="Logo" src="./../../../public/frontend/media/logos/logo3.png" class="h-25px app-sidebar-logo-mini" />
                        </a>
                        <!--end::Logo image-->
                        <!--begin::Sidebar toggle-->
                        <!--begin::Minimized sidebar setup:
        if (isset($_COOKIE["sidebar_minimize_state"]) && $_COOKIE["sidebar_minimize_state"] === "on") { 
            1. "src/js/layout/sidebar.js" adds "sidebar_minimize_state" cookie value to save the sidebar minimize state.
            2. Set data-kt-app-sidebar-minimize="on" attribute for body tag.
            3. Set data-kt-toggle-state="active" attribute to the toggle element with "kt_app_sidebar_toggle" id.
            4. Add "active" class to to sidebar toggle element with "kt_app_sidebar_toggle" id.
        }
    -->
    <div id="kt_app_sidebar_toggle" class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate" :class="{'active': !this.isOpenMenu}" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize" @click="toggleMenu()">
								<i class="ki-duotone ki-black-left-line fs-3 rotate-180">
									<span class="path1"></span>
									<span class="path2"></span>
								</i>
							</div>
                        <!--end::Sidebar toggle-->
                    </div>
                    <!--end::Logo-->
                    <!--begin::sidebar menu-->
                    <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
                        <!--begin::Menu wrapper-->
                        <div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper" style="height: calc(100vh - 100px); overflow-y: scroll;scrollbar-color: transparent transparent;scrollbar-width: thin;">
                            <!--begin::Scroll wrapper-->
                            <div id="kt_app_sidebar_menu_scroll" class="scroll-y my-5 mx-3" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
                                <!--begin::Menu-->
                                <div class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                

                                    <!--begin:Menu item-->
                                        <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-home fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile"> {{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.Home }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->

                                    <!--begin:Menu item-->
                                    <div class="menu-item pt-5">
                                        <!--begin:Menu content-->
                                        <div class="menu-content">
                                            <span class="menu-heading fw-bold text-uppercase fs-7">{{ this.$NOLIMITResource[this.dataUser.language].SibarCategoryMenu.Account }}</span>
                                        </div>
                                        <!--end:Menu content-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link to="/userProfile" class="menu-link">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-user fs-2">

                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.dataUser.name }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->

                                     <!--begin:Menu item-->
                                     <div v-if="this.isAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link to="/listUserByAccountant" class="menu-link">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-user fs-2">

                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AccountantManager }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                      
                                </div>
                                <!--end::Menu-->
                                 <!--begin::Menu-->
                                 <div class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                                    <!--begin:Menu item-->
                                    <div class="menu-item pt-5">
                                        <!--begin:Menu content-->
                                        <div class="menu-content">
                                            <span class="menu-heading fw-bold text-uppercase fs-7">{{ this.$NOLIMITResource[this.dataUser.language].SibarCategoryMenu.AdvertisingAccountManager }}</span>
                                        </div>
                                        <!--end:Menu content-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/adsAccount">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-avalanche fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AdvertisingAccount }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/topUpAccount">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-abstract-26 fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.TopupList }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/balanceHistory">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-wallet fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.WalletHistory }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->

                                    <!--begin:Menu item-->
                                    <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/listRefund">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-entrance-right  fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.RefundHistory }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                </div>
                                <!--end::Menu-->
                                 <!--begin::Menu-->
                                 <div class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                                    <!--begin:Menu item-->
                                    <div class="menu-item pt-5">
                                        <!--begin:Menu content-->
                                        <div class="menu-content">
                                            <span class="menu-heading fw-bold text-uppercase fs-7">{{ this.$NOLIMITResource[this.dataUser.language].SibarCategoryMenu.Recharge }}</span>
                                        </div>
                                        <!--end:Menu content-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link to="/DepositRecharge" class="menu-link" >
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-dollar fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.CreateRechargeInvoice }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link to="/deposit" class="menu-link" >
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-rocket fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.RechargeInvoiceHistory }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    
                                </div>
                                <!--end::Menu-->
                                <div v-if="this.isAdmin == true || this.isSupport == true || this.isInternalAccountant == true" class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                                    <!--begin:Menu item-->
                                    <div class="menu-item pt-5">
                                        <!--begin:Menu content-->
                                        <div class="menu-content">
                                            <span class="menu-heading fw-bold text-uppercase fs-7">{{ this.$NOLIMITResource[this.dataUser.language].SibarCategoryMenu.AdministrativeFeatures }}</span>
                                        </div>
                                        <!--end:Menu content-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="isInternalAccountant!= true" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link to="/listUser" class="menu-link">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-user  fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.UserList }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="isInternalAccountant!= true" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/listAds">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-avalanche fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AdvertisingAccountListAll }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="isAdmin || isInternalAccountant"   class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/listAllTopUp">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-abstract-26 fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.TopupListManager }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/depositAll">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-rocket fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AllRechargeInvoice }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/binanceDepositListAll">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-bitcoin fs-2">
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.RechargeInvoiceBinanceHistory }}</span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                     <!--begin:Menu item-->
                                     <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/allAdsHistory">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-book-square fs-2">
                                                   
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AllAdsHistory }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->

                                       <!--begin:Menu item-->
                                       <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/allBalanceHistory">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-wallet fs-2">
                                                 
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AllWalletHistory }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->

                                     <!--begin:Menu item-->
                                     <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/allRefund">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-entrance-right fs-2">

                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AllRefundHistory }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->

                                    <!--begin:Menu item-->
                                              <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/billLog">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-bill fs-2">

                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.BillLogManager }}  </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->

                                           <!--begin:Menu item-->
                                           <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/cardManager">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-two-credit-cart fs-2">

                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.CardManager }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                                          <!--begin:Menu item-->
                                                          <div v-if="isAdmin || isInternalAccountant" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/bankFeeCard">
                                            <span class="menu-icon">
                                                <i class="ki-solid ki-bill fs-2">

                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].BankFeeCard }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                      <!--begin:Menu item-->
                                      <div v-if="isAdmin" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/allRequestAds">
                                            <span class="menu-icon">
                                               
                                                <i class="ki-solid ki-courier-express fs-2">
                                                 
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AllRequestAds }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="isAdmin" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/adsSpendHistory">
                                            <span class="menu-icon">
                                               
                                                <i class="ki-solid ki-chart-line-up fs-2">
                                                 
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.AdsSpendHistory }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="this.isAdmin == true || this.isSupport == true" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/topupThai">
                                            <span class="menu-icon">
                                               
                                                <i class="ki-solid ki-setting-2 fs-2">
                                                 
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.TopupThai }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    <!--begin:Menu item-->
                                    <div v-if="isAdmin" class="menu-item">
                                        <!--begin:Menu link-->
                                        <router-link class="menu-link" to="/setting">
                                            <span class="menu-icon">
                                               
                                                <i class="ki-solid ki-setting-2 fs-2">
                                                 
                                                </i>
                                            </span>
                                            <span class="menu-title" @click="onCloseSidebarMobile">{{ this.$NOLIMITResource[this.dataUser.language].SidebarCategory.Setting }} </span>
                                        </router-link>
                                        <!--end:Menu link-->
                                    </div>
                                    <!--end:Menu item-->
                                    
                                </div>
                                <!--end::Menu-->
                                
                            </div>
                            <!--end::Scroll wrapper-->
                        </div>
                        <!--end::Menu wrapper-->
                    </div>
                    <!--end::sidebar menu-->
                    
                </div>
                <!--end::Sidebar-->
</template>
<style scope>
.app-sidebar-logo-default{
    width: 208px;
    height: auto !important;
}
.app-sidebar-logo-mini{
    width: 40px;
    height: auto !important;
}
.menu-link{
    padding-right: 0px !important;
    text-align: start;
}
</style>
<script>
  import {mapMutations,mapGetters} from "vuex";
    export default{
        name:"SidebarMenu",
        data() {
            return {
                dataUser:{language:"VN"},
                isAdmin:false,
                isInternalAccountant:false,
                isSupport:false,
                isOpenMenu:true,
                isAccountant:false,
            }
        },
        created() {
            this.$apiService
            .getUserProfile()
            .then((res) => {
                if(res.data.data.language == "VN" || res.data.data.language == "EN"){
                    this.dataUser = res.data.data;
                }else{
                    this.dataUser = res.data.data;
                    this.dataUser.language = "VN";
                }
                
                if(res.data.data.isAdmin == true){
                    this.isAdmin = true
                }else{
                    this.isAdmin = false
                }
                if(res.data.data.isAccountant == true){
                    this.isAccountant = true
                }else{
                    this.isAccountant = false
                }
                if(res.data.data.isInternalAccountant == true){
                    this.isInternalAccountant = true
                }else{
                    this.isInternalAccountant = false
                }
                if(res.data.data.isSupport == true){
                    this.isSupport = true
                }else{
                    this.isSupport = false
                }
            })
            .catch((error) => {
                console.error("Lỗi khi lấy dữ liệu:", error);
            });
        },
        computed:{
            ...mapGetters("listModule", [
            "getOnShowSidebarMobile",

            ]),
        },
        methods: {
            ...mapMutations("listModule", ["onOpenSidebarMobile","onCloseSidebarMobile"]),
            formatCurrency(number) {
            const formattedCurrency = number?.toLocaleString('vi-VN', {
                style: 'currency',
                currency: 'VND'
            });
            return formattedCurrency;
            },
            toggleMenu(){
                this.isOpenMenu = !this.isOpenMenu;
                if(this.isOpenMenu == false){
                    document.documentElement.style.setProperty('--bs-app-sidebar-width', '53px');
                }else{
                    document.documentElement.style.setProperty('--bs-app-sidebar-width', '265px');
                }
                
            },
           
        },  
    }
</script>